import { AutoAwesome } from "@mui/icons-material";
import { Typography } from "@mui/material";


export default function Logo(){

    return (
        <Typography variant='h2'>

            <AutoAwesome sx={{mr: 1}} color='primary'/>

            <b>Review</b>Lib
                        
        </Typography>
    )
}