type Method = 'GET' | 'POST' | 'PUT' | 'DELETE'

type Tokens = {
    access: string
    refresh: string
}

type JsonResponse = {
    ok: boolean
    status: number
    data: any
}

const API = {

    accessToken: '',

    url(path: string){

        const baseUrl = window.location.host.startsWith('localhost') ?
            'http://localhost:3000' :
            'https://reviewlib-1cd4258b5ac2.herokuapp.com';

        return baseUrl + path
    },

    getFetchOptions(method: Method, data: any, bearerToken: string): RequestInit {

        let options: RequestInit =  {
            method: method,
            headers: {
                Authorization: `Bearer ${bearerToken}`,
            },
            mode: 'cors',
        };

        if(data instanceof FormData){

            options.body = data;

        }else if(data){

            options.headers= {...options.headers, 'Content-Type': 'application/json'};
            options.body = JSON.stringify(data);
        }

        return options;
    },

    async send(method: Method = 'GET', path: string, data: any, bearerToken: string, refreshed: boolean): Promise<JsonResponse> {

        const url = this.url(path);

        const options = this.getFetchOptions(method, data, bearerToken);

        try{

            const response = await fetch(url, options);

            const responseData = await response.json();
    
            if(response.status === 401 && !refreshed && this.hasRefreshToken()){

                await this.refreshToken();

                return await this.send(method, path, data, this.getAccessToken(), true);

            }else if(!response.ok){

                throw new ApiError(response.status, responseData);
            }
    
            return {
                ok: response.ok,
                status: response.status,
                data: responseData
            };

        }catch(error){

            throw error;
        }
    },

    async refreshToken(): Promise<void> {

        const refreshUrl = this.url('/refresh');

        const refreshOptions = this.getFetchOptions('GET', null, this.getRefreshToken());
    
        try{

            const refreshResponse = await fetch(refreshUrl, refreshOptions)

            const refreshData = await refreshResponse.json()
    
            if(refreshResponse.ok){

                this.setTokens(refreshData);

            }else{

                throw new ApiError(refreshResponse.status, refreshData);
            }

        }catch(error){

            throw error;
        }
    },

    async get(path: string): Promise<JsonResponse> {

        return await this.send('GET', path, null, this.getAccessToken(), false);
    },

    async post(path: string, data: any): Promise<JsonResponse> {


        return await this.send('POST', path, data, this.getAccessToken(), false);
    },

    async put(path: string, data: any): Promise<JsonResponse> {

        return await this.send('PUT', path, data, this.getAccessToken(), false);
    },

    async delete(path: string): Promise<JsonResponse> {

        return await this.send('DELETE', path, null, this.getAccessToken(), false);
    },

    setTokens(tokens: Tokens): void {
        window.localStorage.setItem('refresh_token', tokens.refresh);
        this.accessToken = tokens.access;
    },

    removeTokens(): void {
        window.localStorage.removeItem('refresh_token');
        this.accessToken = '';
    },

    hasAccessToken(): boolean {
        return this.accessToken ? true : false;
    },

    hasRefreshToken(): boolean {
        return window.localStorage.getItem('refresh_token') ? true : false;
    },

    getRefreshToken(): string {
        return window.localStorage.getItem('refresh_token') || '';
    },
    
    getAccessToken(): string {
        return this.accessToken || '';
    },
}

class ApiError extends Error {
    
    public response: JsonResponse
  
    constructor(status: number, data: any) {
        super("API error");
        this.name = "API Error";
        this.response = {status, data, ok: false}
    }
  }

export default API;