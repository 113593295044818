import { Attachment, CloudUpload, Delete} from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Alert, Box, Chip, IconButton, Stack, useTheme  } from "@mui/material";
import { useState } from "react";

type FileProps = {
    id: string
    label?: string
    accept?: string[]
    maxSize?: number
    url?: string | null,
    onChange: (file: File | null) => void
    loading?: boolean
    image?: boolean
}

export default function File({id, label, accept, maxSize, url, onChange, loading, image}: FileProps){

    const theme = useTheme()

    const [active, setActive] = useState<boolean>(false)

    const [fileError, setFileError]  = useState<string | null>(null)

    const handleFile = (file: File) => {

        if(maxSize && file.size > maxSize) return setFileError('Fichier trop lourd')

        if(accept){

            const ext = file.name.split('.').pop() || ''

            if(!accept.includes(file.type) && !accept.includes(ext)){
                return setFileError('Format incorrect')
            }
        }

        onChange(file)
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {

        setFileError(null)

        let files: FileList | null = e.target.files

        if(files && files.length > 0) handleFile(files[0])
    }

    const handleDelete = (): void => {

        setFileError(null)

        onChange(null)
    }


    return <Stack spacing={1}>

        <Stack 
            sx={{
                height: 100, 
                border: 'dashed 2px', 
                borderColor: active ? theme.palette.primary.light : 'lightgrey', 
                borderRadius: '5px',
            }} 
            justifyContent='center'
            onMouseEnter={() => setActive(true)}
            onMouseLeave={() => setActive(false)}
        >

            <LoadingButton 
                startIcon={<CloudUpload />}
                onClick={() => document.getElementById(id)?.click()}
                sx={{height: '100%'}}
                loading={loading ? true : false}
            >
                {label || 'Choisir un fichier'}
            </LoadingButton>

        </Stack>

        {fileError &&
            <Alert severity='error'>{fileError}</Alert>
        }

        {(url && !image) &&
            <Box>
                <Chip 
                    label={url.split('/').pop()}
                    icon={<Attachment />}
                    component="a"
                    href={url}
                    target='_blank'
                    rel='noopener noreferrer'
                    download 
                    clickable
                    onDelete={handleDelete}
                />
            </Box>
        }

        {(url && image) && 

            <Stack direction='row' spacing={1} alignItems='start'>

                <img
                    width={200}
                    src={url}
                    alt="Logo"
                />

                <IconButton onClick={handleDelete}>
                    <Delete />
                </IconButton>

            </Stack>
        }
    

        <input 
            id={id}
            type='file'
            accept={(accept || []).join(',')}
            onChange={handleChange}
            style={{display: 'none'}}
        />

    </Stack>
}