export interface CSVRow {
    [key: string]: string;
}
  
export default function parseCSV(content: string): CSVRow[] {

    const lines = content.split('\n');

    const firstLine = lines[0].split(',');
  
    return lines.map(line => {

        const values = line.split(',');

        return firstLine.reduce((object: CSVRow, header: string, index: number) => {

            object[index] = (values[index] || '').replaceAll('"', '');

            return object;

        }, {});
    });

  }