import { Stack, TextField as Field } from "@mui/material";
import { useEffect, useState } from "react";
import { User } from "../../models";
import Loader from "../../components/ui/Loader";
import Phone from "../../components/form/Phone";
import API from "../../services/API";
import { LoadingButton } from "@mui/lab";
import { useDispatch } from "react-redux";
import { setUser } from "../../redux/userSlice";
import { setToast } from "../../redux/toastSlice";
import { Check } from "@mui/icons-material";

export default function UserForm(){

    const dispatch = useDispatch()

    const [data, setData] = useState<User | null>(null)

    const [status, setStatus] = useState<'loading' | 'ready' | 'submit'>('loading')

    useEffect(() => {

        API.get('/me')
            .then(r => {
                setData(r.data)
                setStatus('ready')
            })

    }, [])

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()

        API.put('/me', data)
            .then(r => {
                dispatch(setUser(r.data))
                dispatch(setToast({
                    message: "Vos informations ont été enregistrées avec succès.", 
                    severity: 'success'
                }))
            })
            .catch(e => {
                dispatch(setToast({
                    message: e?.data?.error || "Une erreur s'est produite.", 
                    severity: 'error'
                }))
            })
            .finally(() => setStatus('ready'))

    }

    return <form onSubmit={handleSubmit}>

        {data && <Stack spacing={3}>

            <Field
                label='Nom'
                size='small'
                value={data.lastname}
                onChange={e => setData({...data, lastname: e.target.value})}
                required
            />

            <Field
                label='Prénom'
                size='small'
                value={data.firstname}
                onChange={e => setData({...data, firstname: e.target.value})}
                required
            />

            <Field
                label='Email'
                size='small'
                type='email'
                value={data.email}
                onChange={e => setData({...data, email: e.target.value})}
                required
                autoComplete="username"
            />

            <Phone
                value={data.phone}
                onChange={e => setData({...data, phone: e.target.value})}
                required
            />

            <LoadingButton
                type='submit'
                variant='outlined'
                startIcon={<Check />}
                loading={status === 'submit'}
            >
                Enregistrer
            </LoadingButton>

        </Stack>}

        {status === 'loading' && <Loader />}

    </form>
}