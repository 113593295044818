import { LoadingButton } from "@mui/lab";
import { Alert, Button, LinearProgress, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { Link, Navigate, useParams } from "react-router-dom";
import API from "../../services/API";
import { useDispatch } from "react-redux";
import { setToast } from "../../redux/toastSlice";


export default function Pay(){

    const [status, setStatus] = useState<'loading' | 'success' | 'error' | 'retry'>('loading')

    const {subscription} = useParams()

    const dispatch = useDispatch()

    useEffect(() => {

        setStatus('loading')

        API.get(`/subscriptions/${subscription}/check`)
            .then(r => {

                console.log(r.data)

                dispatch(setToast({
                    message: "Votre abonnement est validé !", 
                    severity: 'success'
                }))

                setStatus('success')
            })
            .catch(e => {
                console.error(e.response?.status, e.response?.data)
                setStatus('error')
            })

    }, [subscription, dispatch])
    

    const handleRetry = () => {

        setStatus('loading')

        API.get(`/subscriptions/${subscription}/check`)
            .then(r => {
                console.log(r.data)

                dispatch(setToast({
                    message: "Votre abonnement est validé !", 
                    severity: 'success'
                }))

                setStatus('success')
            })
            .catch(e => {

                console.error(e.response?.status, e.response?.data)
                
                API.get(`/subscriptions/${subscription}/pay`)
                    .then(r => {
                        window.location.href = r.data.paymentUrl
                    })
                    .catch(_ => setStatus('error'))
            })

    }

    if(status === 'success') return <Navigate to="/" />

    return <div className="layout-center">

        <Stack spacing={3} sx={{maxWidth: 500, m: 2}}>

            <Typography variant="h2">Validation de votre abonnement</Typography>

            {status === 'loading' &&
            
                <Stack>

                    <LinearProgress color="info" />

                    <Alert severity="info">
                        Veuillez patienter, nous validons votre abonnement...
                    </Alert>

                </Stack>
            }

            {['error', 'retry'].includes(status) &&
            
                <Stack spacing={2}>

                    <Alert severity="error">
                        Votre paiement n'a pas été validé. Veuillez réessayer.
                    </Alert>

                    <LoadingButton
                        loading={status === 'retry'}
                        onClick={handleRetry}
                        variant='contained'
                    >
                        Réessayer
                    </LoadingButton>

                    <Button component={Link} to="/" variant="outlined">
                        Retour au menu
                    </Button>

                </Stack>
            }

        </Stack>

    </div>
}