import { useEffect, useState } from "react"
import { Review } from "../../models"
import API from "../../services/API"
import { useParams } from "react-router-dom"
import { IconButton, Stack } from "@mui/material"
import { ArrowBack, ArrowForward } from "@mui/icons-material"
import ReviewCard from "./ReviewCard"

export default function Embed(){

    const {uuid} = useParams()

    const [status, setStatus] = useState<'loading' | 'ready'>('loading')

    const [reviews, setReviews] = useState<Review[]>([])

    const [index, setIndex] = useState<number>(0)

    useEffect(() => {

        API.get(`/companies/${uuid}`)
            .then(r => {
                setReviews(r.data.reviews)
                setStatus('ready')
            })

    }, [uuid])


    if(status === 'loading') return <></>

    return (
        <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{width: 500}}>

            <IconButton
                onClick={() => setIndex(index - 1)}
                disabled={index <= 0}
            >
                <ArrowBack />
            </IconButton>

            <ReviewCard 
                review={reviews[index]}
            />

            <IconButton
                onClick={() => setIndex(index + 1)}
                disabled={index >= reviews.length - 1}
            >
                <ArrowForward />
            </IconButton>

        </Stack>
    )
}

