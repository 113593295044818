import { Stack, Typography as Typo, TextField as Field, Alert, Box } from "@mui/material";
import { useState } from "react";
import { LoadingButton } from '@mui/lab';
import API from "../../services/API";

export default function ForgotPassword(){

    const [email, setEmail] = useState('')

    const [status, setStatus] = useState<'ready' | 'submit' | 'success' | 'error'>('ready')

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {

        event.preventDefault()

        setStatus('submit')

        API.put('/password/forgot', {email})
            .then(_ => setStatus('success'))
            .catch(_ => setStatus('error'))
    }

    return <form onSubmit={handleSubmit} className='layout-center'>

        <Box sx={{py: 3, px: 2, width}}>

            <Stack spacing={3}>

                <Typo variant='h2'>Mot de passe oublié</Typo>

                <span>Entrez l'adresse mail de votre compte ReviewLib. Nous vous enverrons un lien pour réinitialiser votre mot de passe.</span>

                {status === 'error' &&
                    <Alert severity='error'>
                        Aucun compte ne correspond à cette adresse mail.
                    </Alert>
                }

                {status === 'success' &&
                    <Alert severity='success'>
                        Un email vous a été envoyé à l'adresse {email}.
                    </Alert>
                }

                {status !== 'success' && <>

                    <Field
                        label='Email'
                        size='small'
                        type='email'
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                        required
                    />

                    <LoadingButton
                        loading={status === 'submit'}
                        variant='contained'
                        type='submit'
                    >
                        Se connecter
                    </LoadingButton>

                </>}

            </Stack>

        </Box>

    </form>
}

const width = window.innerWidth > 800 ? 500 : 320