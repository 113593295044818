import { Stack, TextField, MenuItem } from "@mui/material";
import countries from '../../data/countries.json';
import cities from '../../data/cities.json';

type Country = {
    code: string,
    name: string
}

type City = {
    zip: string,
    name: string
}

type AddressData = {
    address: string;
    zip: string;
    city: string;
    country: string;
};

interface AddressProps {
    value: AddressData;
    onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, data: AddressData) => void;
}

export default function Address({ value, onChange }: AddressProps) {

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {

    let copy: AddressData = { ...value };
    
    copy[e.target.name as keyof AddressData] = e.target.value;

    if(e.target.name === 'zip'){
        let city: City | undefined = cities.find(c => c.zip === e.target.value);
        
        if(city) copy.city = city.name;
    }

    onChange(e, copy);
  }

  return <>

        <TextField
            label='Adresse'
            size='small'
            value={value.address}
            onChange={handleChange}
            required
            name='address'
        />

        <Stack direction='row' spacing={1}>

            <TextField
                label='Code postal'
                size='small'
                value={value.zip}
                onChange={handleChange}
                required
                sx={{ flex: 1 }}
                name='zip'
            />

            <TextField
                label='Ville'
                size='small'
                value={value.city}
                onChange={handleChange}
                required
                sx={{ flex: 2 }}
                name='city'
            />

        </Stack>

        <TextField
            label='Pays'
            size='small'
            select
            value={value.country}
            onChange={handleChange}
            required
            name='country'
        >
            {countries.map((c: Country) => 
                <MenuItem value={c.code} key={c.code}>{c.name}</MenuItem>   
            )} 
        </TextField>

    </>
}

