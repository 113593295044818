import { Stack, Typography as Typo, Link as A, TextField as Field, Alert, Box, Typography} from "@mui/material";
import { useState } from "react";
import { LoadingButton } from '@mui/lab';
import API from "../../services/API";
import Password from "../../components/form/Password";
import { useNavigate, Link } from "react-router-dom"
import { useDispatch } from 'react-redux'
import { setUser } from '../../redux/userSlice'

type LoginData = {
    email: string 
    password: string
}

export default function Login(){

    const dispatch = useDispatch()

    const navigate = useNavigate()

    const [data, setData] = useState<LoginData>({
        email: '',
        password: '',
    })

    const [status, setStatus] = useState<string>('ready')

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {

        event.preventDefault()

        setStatus('submit')

        API.post('/login', data)
            .then(r => {
                dispatch(setUser(r.data))
                API.setTokens(r.data.tokens)
                navigate('/')
            })
            .catch(e => {
                setStatus('error')
            })
    }

    return <form onSubmit={handleSubmit} className='layout-center'>

        <Box sx={{py: 3, px: 2, minWidth: minWidth}}>

            <Stack spacing={3}>

                <Typo variant='h2'>Connexion à ReviewLib</Typo>

                <Typo>Vous n'avez pas encore de compte ? <A component={Link} to='/subscribe'>Inscrivez-vous</A></Typo>

                {status === 'error' &&
                    <Alert severity='error'>Vos identifiants sont erronés.</Alert>
                }

                <Field
                    label='Email'
                    size='small'
                    type='email'
                    value={data.email}
                    onChange={e => setData({...data, email: e.target.value})}
                    required
                    autoComplete="username"
                />

                <Password 
                    value={data.password}
                    onChange={e => setData({...data, password: e.target.value})}
                />

                <Link to="/password/forgot">
                    <Typography variant="body2" color="text.secondary">
                        <u>Mot de passe oublié ?</u>
                    </Typography>
                </Link>

                <LoadingButton
                    loading={status === 'submit'}
                    variant='contained'
                    type='submit'
                >
                    Se connecter
                </LoadingButton>

            </Stack>

        </Box>

    </form>
}

const minWidth = window.innerWidth > 800 ? 500 : 300