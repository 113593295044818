import { Close, ContentCopy, Facebook, LinkedIn, Twitter } from "@mui/icons-material";
import { Box, Dialog, DialogContent, DialogTitle, IconButton, Stack, Tooltip } from "@mui/material"
import { useDispatch } from "react-redux";
import { setToast } from "../../redux/toastSlice";

type ShareModalProps = {
    link: string;
    title?: string;
    open: boolean;
    onClose: () => void;
}

export default function ShareModal({link, title, open, onClose}: ShareModalProps){

    const dispatch = useDispatch()

    const handleCopy = () => {

        try{

            navigator.clipboard.writeText(link)

            dispatch(setToast({
                severity: 'success',
                message: "Le lien a été copié dans le presse-papier.",
                duration: 6000
            }))

        }catch(e){
            console.error(e)
        }
    }

    return <Dialog open={open} onClose={onClose}>

        <Stack direction='row' justifyContent='space-between' alignItems='center'>

            <DialogTitle>{title || 'Partager'}</DialogTitle>

            <Box  sx={{p: 1}}>
                <IconButton size='small' onClick={onClose}>
                    <Close />
                </IconButton>
            </Box>

        </Stack>

        <DialogContent>

            <Stack direction='row' justifyContent='center' spacing={2}>

                {networks.map(n => 
                    <Tooltip title={n.name} placement="top" key={n.name}>
                        <IconButton 
                            href={n.url.replaceAll('{link}', encodeURI(link))}
                            target="_blank"
                            rel="noopener noreferrer"
                            color="success"
                        >
                            {n.icon}
                        </IconButton>
                    </Tooltip>
                )}

                <Tooltip title="Copier le lien" placement="top">
                    <IconButton 
                        onClick={handleCopy}
                        color="success"
                    >
                        <ContentCopy />
                    </IconButton>
                </Tooltip>
                    
            </Stack>

        </DialogContent>

    </Dialog>
}

const networks = [
    {
        name: 'Facebook',
        url: `https://www.facebook.com/sharer/sharer.php?u={link}`,
        icon: <Facebook />
    },
    {
        name: 'Twitter',
        url: `https://twitter.com/intent/tweet?url={link}&text={link}`,
        icon: <Twitter />
    },
    {
        name: 'LinkedIn',
        url: `https://www.linkedin.com/sharing/share-offsite/?url={link}`,
        icon: <LinkedIn />
    },

];

const encodeURI = (url: string) => encodeURIComponent(url);