import { Box, Chip, SxProps } from "@mui/material"
import reviewStatus from "../../data/reviewStatus"

type StatusChipProps = {
    value: 'created' | 'sent' | 'opened' | 'started' | 'completed'
    size?: 'small' | 'medium'
    sx?: SxProps
}

export default function StatusChip({value, size, sx}: StatusChipProps){

    return <Box sx={sx}>
        <Chip
            label={reviewStatus[value]?.title || ''} 
            color={reviewStatus[value]?.color || 'default'}
            size={size}
        />
    </Box>
}