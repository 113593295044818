import { ArrowBack } from "@mui/icons-material";
import { IconButton, Stack, Typography as Typo } from "@mui/material";
import User from "../components/ui/User";
import Private from "./Private";
import { Link } from "react-router-dom";
import Loader from "../components/ui/Loader";
import Logo from "./Logo";
import MenuOptions from "./MenuOptions";
import MobileHeader from "./MobileHeader";

type LayoutProps = {
    tab: string,
    title?: string,
    backLink?: string,
    loading?: boolean,
    children: React.ReactNode
}

export default function Layout({tab, title, backLink, loading, children}: LayoutProps){

    return (
        <div className="layout">
            
            <nav className="layout-menu">

                <div>

                    <div className="layout-menu-top">
                        <Logo />
                    </div>
                        
                    <Stack className="layout-menu-options">

                        <MenuOptions tab={tab} />
                        
                    </Stack>

                </div>
                    
                <User />
                
            </nav>

            
            <main className="layout-page">
            
                <MobileHeader tab={tab} />
                
                <div className="layout-page-content">

                    <Stack direction='row' alignItems='center' spacing={1} sx={{mb: 3}}>

                        {backLink &&
                            <IconButton component={Link} to={backLink}>
                                <ArrowBack />
                            </IconButton>
                        }

                        <Typo variant='h1'>{title}</Typo>

                    </Stack>

                    <Private>{children}</Private>

                    {loading && <Loader />}

                </div>
            </main>

        </div>
        
    )
}

