import { Stack, Typography as Typo, Link as A, TextField as Field, Alert, Box } from "@mui/material";
import { useEffect, useState } from "react";
import { LoadingButton } from '@mui/lab';
import API from "../../services/API";
import Password from "../../components/form/Password";
import { Link, useParams, Navigate } from "react-router-dom"
import Address from "../../components/form/Address";
import { useDispatch } from 'react-redux';
import { setUser } from '../../redux/userSlice';
import Phone from "../../components/form/Phone";

type RegisterData = {
    lastname: string 
    firstname: string 
    email: string 
    password: string
    phone: string
    company: string 
    address: string 
    zip: string 
    city: string
    country: string
    subscription: string
}

const subscriptions = ['essentiel','harmonie'];

export default function Register(){

    const {subscription} = useParams()

    const dispatch = useDispatch()

    const [data, setData] = useState<RegisterData>({
        lastname: '',
        firstname: '', 
        email: '',
        password: '',
        phone: '',
        company: '', 
        address: '',
        zip: '',
        city: '',
        country: 'FR',
        subscription: subscription || ''
    })

    const [status, setStatus] = useState<'ready' | 'submit' | 'error' | 'subscribe'>('ready')

    const [message, setMessage] = useState<string>('')

    useEffect(() => {

        if( !subscriptions.includes(subscription || '') ){

            setStatus('subscribe')
        }

    }, [subscription])


    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {

        event.preventDefault()

        setStatus('submit')

        API.post('/register', data)
            .then(r => {
                dispatch(setUser(r.data.user))
                API.setTokens(r.data.tokens)
                window.location.href = r.data.paymentUrl // Redirect to Stripe payment console
            })
            .catch(e => {
                setStatus('error')
                console.log(e)
                setMessage(e.response?.data?.error || "Une erreur s'est produite.")
            })
    }

    if(status === 'subscribe') return <Navigate to='/subscribe' />

    return <form onSubmit={handleSubmit} className='layout-center'>

        <Box sx={{minWidth: minWidth, p: 2}}>

            <Stack spacing={2}>

                <Typo variant='h2'>Inscription à ReviewLib</Typo>

                <Typo>Vous avez déjà un compte ? <A component={Link} to='/login'>Connectez-vous</A></Typo>

                {status === 'error' &&
                    <Alert severity='error'>{message}</Alert>
                }

                <Field
                    label='Nom'
                    size='small'
                    value={data.lastname}
                    onChange={e => setData({...data, lastname: e.target.value})}
                    required
                />

                <Field
                    label='Prénom'
                    size='small'
                    value={data.firstname}
                    onChange={e => setData({...data, firstname: e.target.value})}
                    required
                />

                <Field
                    label='Email'
                    size='small'
                    type='email'
                    value={data.email}
                    onChange={e => setData({...data, email: e.target.value})}
                    required
                    autoComplete="username"
                />

                <Phone
                    value={data.phone}
                    onChange={e => setData({...data, phone: e.target.value})}
                    required
                />

                <Field
                    label='Entreprise'
                    size='small'
                    value={data.company}
                    onChange={e => setData({...data, company: e.target.value})}
                    required
                />

                <Address 
                    value={data}
                    onChange={(_, v) => setData({...data,
                        address: v.address,
                        zip: v.zip,
                        city: v.city,
                        country: v.country
                    })}
                />

                <Password 
                    value={data.password}
                    onChange={e => setData({...data, password: e.target.value})}
                />

                <LoadingButton
                    loading={status === 'submit'}
                    variant='contained'
                    type='submit'
                >
                    S'inscrire
                </LoadingButton>

            </Stack>

        </Box>

    </form>
}

const minWidth = window.innerWidth > 800 ? 550 : 300