import { Stack, SxProps, Typography, LinearProgress } from "@mui/material"
import { DataGrid, GridColDef, GridToolbarContainer } from "@mui/x-data-grid"

type GridProps = {
    rows: any[],
    columns: GridColDef[],
    pageSize?: number,
    onSelect?: (ids: any[]) => void,
    sx?: SxProps,
    loading?: boolean,
    toolbar?: JSX.Element
}

export default function Grid({rows, columns, pageSize = 10, onSelect, sx, loading, toolbar}: GridProps){

    function Toolbar(){

        return toolbar ? 
                <GridToolbarContainer>
                    {toolbar}
                </GridToolbarContainer> 
            : null
    }

    return <DataGrid 
        rows={rows}
        columns={columns}
        initialState={{
            pagination: {
                paginationModel: {
                    pageSize: pageSize,
                },
            },
        }}
        pageSizeOptions={[5, 10, 20, 25, 30, 50, 100]}
        checkboxSelection={onSelect ? true : false}
        disableRowSelectionOnClick={true}
        onRowSelectionModelChange={onSelect}
        sx={sx}
        loading={loading}
        slots={{
            toolbar: Toolbar,
            loadingOverlay: LinearProgress,
            noRowsOverlay: Empty,
            noResultsOverlay: Empty,
        }}
    />
}


function Empty(){

    return <Stack justifyContent='center' alignItems='center' sx={{height: 300}}>
        <Typography variant='body2' color='text.secondary'>
            Aucune données à afficher
        </Typography>
    </Stack>
}

