import { Stack, Typography, Paper, IconButton, Box } from "@mui/material";
import { useEffect, useState } from "react";
import Loader from "../../components/ui/Loader";
import API from "../../services/API";
import { Download } from "@mui/icons-material";
import moment from "moment";

export default function Invoices(){

    const [invoices, setInvoices] = useState<any[]>([])

    const [status, setStatus] = useState<'loading' | 'ready'>('loading')

    useEffect(() => {

        API.get('/invoices')
            .then(r => {
                setInvoices(r.data)
                setStatus('ready')
            })

    }, [])

    return (

        <Stack spacing={2}>

            {status === 'loading' && <Loader />}

            {invoices.map(i => 
                
                <Paper variant='outlined' sx={{p: 1}} key={i.id}>

                    <Stack direction='row' alignItems='center' justifyContent='space-between' flexWrap='wrap' spacing={1}>

                        <Box>
                            <IconButton href={i.invoice_pdf} target="_blank" rel="noopener noreferrer">
                                <Download />
                            </IconButton>
                        </Box>

                        <Typography>
                            Facture du {moment(i.period_start * 1000).format('DD/MM/YYYY')}
                        </Typography>

                        <Typography>
                            {(i.amount_due / 100).toLocaleString()}€ HT
                        </Typography>

                    </Stack>

                </Paper>
            )}

        </Stack>
    )
}