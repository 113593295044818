import { useState } from "react"
import FileInput from "../../components/form/File"
import { Company } from '../../models';
import API from "../../services/API";
import { useDispatch } from "react-redux";
import { setToast } from "../../redux/toastSlice";

type CompanyLogoProps = {
    company: Company
    onChange: (company: Company) => void
}

export default function CompanyLogo({ company, onChange }: CompanyLogoProps){

    const [loading, setLoading] = useState(false)

    const dispatch = useDispatch()

    const handleChange = (file: File | null) => {

        if(file){

            setLoading(true)

            const data = new FormData()
            data.append('file', file)

            API.post('/files', data)
                .then(r => onChange({...company, logo: r.data.url}))
                .catch(e => {

                    console.log(e?.response?.data)

                    dispatch(setToast({
                        severity: 'error',
                        message: "Image trop lourde ou format incorrect"
                    }))

                })
                .finally(() => setLoading(false))

        }else{

            onChange({...company, logo: null})
        }
    }

    return (
        <FileInput 
            id="company-logo"
            label="Importez le logo de votre entreprise"
            accept={['jpg','jpeg','png','svg']}
            maxSize={1000000}
            url={company.logo}
            onChange={handleChange}
            loading={loading}
            image={true}
        />
    )
}