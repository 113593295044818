import { Box, Stack } from "@mui/material";
import API from "../../services/API";

type LogoProps = {
    fileName: string | null | undefined
}

export default function Logo({fileName}: LogoProps){

    return !fileName ? <></> :

        <Stack alignItems='center'>

            <Box width={180}>
                <img 
                    src={API.url(`/files/${fileName}`)}
                    alt="Logo"
                    width="100%"
                />
            </Box>

        </Stack>
}