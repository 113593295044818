import { Navigate } from 'react-router-dom'
import API from '../../services/API'
import { useDispatch } from 'react-redux'
import { setUser } from '../../redux/userSlice'
import { useEffect } from 'react'

export default function Logout(){

    const dispatch = useDispatch()

    useEffect(() => {

        API.removeTokens()

        dispatch(setUser(null))

    }, [dispatch])

    return <Navigate to='/login' />
}