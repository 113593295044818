import { createTheme, Theme } from '@mui/material/styles'
import { frFR } from '@mui/x-data-grid';

export default function generateMUITheme(mainColor: string, backgroundColor: string): Theme {

    return createTheme({

        palette: {
            primary: {
                main: mainColor,
                contrastText: '#fff',
            },
            secondary: {
                main: backgroundColor,
                contrastText: '#fff',
            },
            success: {
                main: mainColor,
                contrastText: '#fff',
            },
            info: {
                main: backgroundColor,
                contrastText: '#fff',
            },
        },

        typography: {
            fontFamily: ['Open Sans','cursive'].join(','),
            fontSize: 16,
            h1: {fontSize: '2rem'},
            h2: {fontSize: '1.8rem'},
            h3: {fontSize: '1.6rem'},
            h4: {fontSize: '1.4rem'},
            h5: {fontSize: '1.2rem'},
            h6: {fontSize: '1.1rem'},
        }

    }, frFR);
}