export interface Contact {
    id?: number, 
    firstname: string,
    lastname: string,
    email: string,
    phone: string | null,
    list: string | null,
    createdAt?: string,
    updatedAt?: string
}

export const emptyContact: Contact = {
    firstname: '',
    lastname: '',
    email: '',
    phone: null,
    list: null
}


