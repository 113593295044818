import { Avatar, Card, CardContent, CardHeader, Grow, Rating, Stack, Typography, useTheme } from "@mui/material";
import { Review } from "../../models";
import moment from "moment";

type ReviewCardProps = {
    review: Review
}

export default function ReviewCard({review}: ReviewCardProps){

    const theme = useTheme()

    return <Grow in={true}>

        <Card sx={{width: 320, m: 2}} elevation={2}>

            <CardHeader
                avatar={
                    <Avatar sx={{bgcolor: theme.palette.primary.main}} aria-label="recipe">
                        {review.name.trim().substring(0,1).toLocaleUpperCase()}
                    </Avatar>
                }
                title={review.name}
                subheader={moment(review.createdAt).format('LL')}
            />

            <Stack direction="row" alignItems="center" spacing={1}sx={{ml: 2}}>

                <Rating 
                    value={review.rating / 20}
                    precision={0.5}
                    readOnly
                />

                <Typography variant="body2" color="text.secondary">
                    {(review.rating / 20).toLocaleString()} / 5
                </Typography>

            </Stack>

            <CardContent>

                <Typography variant="body2" color="text.secondary">
                    {review.comment}
                </Typography>

            </CardContent>

        </Card>

    </Grow>
}