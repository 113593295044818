import { FormControlLabel, Rating, Slider, Stack, Switch, TextField } from "@mui/material"
import { Question } from "../../models"
import { Answer } from "../../models/Answer"

type RenderProps = {
    question: Question,
    answer: Answer | null,
    onChange: (value: any) => void
}

export default function Field({question, answer, onChange}: RenderProps){


    const handleChange = (field: string, value: any) => {

        let answer: Answer = {
            question: question.title,
            type: question.type,
            rank: question.rank,
            dateValue: null,
            numberValue: null,
            textValue: null,
        }

        switch (field) {
            case 'date':
                answer.dateValue = value
                break;
            case 'number':
                answer.numberValue = value
                break;
            default:
                answer.textValue = value;
        }

        onChange(answer)
    }


    return <>


        {question.type === 'stars' &&

            <Stack alignItems='center'>
            
                <Rating
                    value={answer?.numberValue}
                    onChange={(_, value: number | null) => handleChange('number', 
                        Math.round((value || 0) * 20)
                    )}
                    precision={0.5}
                    size='large'
                />

            </Stack>
        }



        {question.type === 'rating' &&
            
            <Stack sx={{p: 2}}>

                <Slider
                    defaultValue={0}
                    marks={[0,1,2,3,4,5,6,7,8,9,10].map(n => {
                        return {label: n.toString(), value: n}
                    })}
                    valueLabelDisplay="on"
                    min={0}
                    max={10}
                    step={1}
                    onChange={(_, value: any) => handleChange('number', 
                        Math.round((Number.isNaN(value) ? 0 : value) * 10)
                    )}
                />

            </Stack>

        }



        {question.type === 'text' &&
        
            <TextField
                value={answer?.textValue || ''}
                required={!question.optional}
                inputProps={{maxLength: 1000}}
                multiline
                minRows={3}
                onChange={e => handleChange('text', e.target.value)}
            />
        }



        {['yesno', 'recommendation'].includes(question.type) &&

            <Stack alignItems='center'>

                <FormControlLabel 
                    control={
                        <Switch 
                            checked={answer?.numberValue === 1} 
                            onChange={(_, value) => handleChange('number', value ? 1 : 0)}
                        />
                    } 
                    label={answer?.numberValue === 1 ? 'Oui' : 'Non'} 
                />

            </Stack>
        }

    </>
}

