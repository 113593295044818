import { Collapse, IconButton } from "@mui/material";
import Logo from "./Logo";
import { Close, Menu } from "@mui/icons-material";
import { useState } from "react";
import MenuOptions from "./MenuOptions";

type MobileHeaderProps = {
    tab: string
}

export default function MobileHeader({ tab }: MobileHeaderProps){

    const [open, setOpen] = useState<boolean>(false)

    return (

        <header className="layout-header">

            <div className="layout-header-top">

                <Logo />

                <IconButton onClick={() => setOpen(!open)}>
                    {open ? <Close sx={iconSx} /> : <Menu sx={iconSx} />}
                </IconButton>

            </div>

            <Collapse in={open}>

                <MenuOptions tab={tab} />

            </Collapse>


        </header>

    );
}

const iconSx = {color: 'white', fontSize: 34}