import { Alert, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import API from "../../services/API";
import Loader from "../../components/ui/Loader";

type ThanksProps = {
    uuid: string | undefined
}

export default function Thanks({uuid}: ThanksProps){

    const [status, setStatus] = useState<'loading' | 'success' | 'error'>('loading')

    useEffect(() => {

        if(uuid){

            API.put(`/reviews/${uuid}/complete`, null)
                .then(_ => setStatus('success'))
                .catch(_ => setStatus('error'))

        }

    }, [uuid])


    if(status === 'loading') return <Loader />


    if(status === 'error') return <Alert severity='error'>Une erreur s'est produite.</Alert>


    return <>

        <Alert severity='success'>
            Merci d'avoir partagé votre avis !
        </Alert>

        <Typography align='justify'>Votre participation est très importante pour nous. Nous avons bien reçu vos réponses et nous vous en sommes reconnaissants. Votre contribution nous aidera à améliorer nos services et à mieux répondre à vos attentes.</Typography>

        <Typography align='justify'>Au plaisir de vous revoir bientôt !</Typography>
    </>
}