import { Alert, Box, Dialog, DialogContent, DialogTitle, IconButton, Rating, Stack, Typography } from "@mui/material"
import { Review } from "../../models"
import { Close } from "@mui/icons-material"
import { useEffect, useState } from "react"
import Loader from "../../components/ui/Loader"
import API from "../../services/API"
import { Answer } from "../../models/Answer"
import StatusChip from "./StatusChip"
import moment from "moment"

type ZoomProps = {
    review: Review | null,
    onClose: () => void,
}

type Status = 'loading' | 'ready' | 'error'

export default function Zoom({review, onClose}: ZoomProps){

    const [answers, setAnswers] = useState<Answer[]>([])

    const [status, setStatus] = useState<Status>('loading')

    useEffect(() => {

        if(review){

            setStatus('loading')

            API.get(`/reviews/${review.id}`)
                .then(r => {
                    setAnswers(r.data?.answers || [])
                    setStatus('ready')
                })
                .catch(e => setStatus('error'))
        }

    }, [review])


    return review && <Dialog open={true} onClose={onClose}>

        <Stack direction='row' justifyContent='space-between' alignItems='center'>

            <DialogTitle>{review.name}</DialogTitle>

            <IconButton onClick={onClose} sx={{m: 1}}>
                <Close />
            </IconButton>
        </Stack>

        <Stack direction='row' spacing={1} sx={{ml: 3}}>

            <StatusChip value={review.status} sx={{ml: 3}}/>

            <Typography><small>Créé le {moment(review.createdAt).format('DD/MM/YYYY à HH:mm')}</small></Typography>

        </Stack>

        <DialogContent sx={{width: window.innerWidth > 600 ? 500 : 300}}>
        
            {answers.length > 0 && 

                <Stack spacing={1}>

                    {answers
                        .sort((a,b) => a.rank - b.rank)
                        .map(a =>
                            <Row answer={a} key={a.id} />    
                        )
                    }

                </Stack>
            }

        </DialogContent>

        {status === 'loading' && 
            <Loader />
        }

        {status === 'error' && 
            <Alert severity='error'>Une erreur s'est produite.</Alert>
        }

    </Dialog>
}


type RowProps = {
    answer: Answer
}

function Row({answer}: RowProps){

    return <>

        <Typography>{answer.rank}. {answer.question}</Typography>

        <Box sx={{background: 'rgb(240,240,240)', borderRadius: '5px', p: 2}}>

            {['rating','stars'].includes(answer.type) &&
                <Stack direction='row' alignItems='center' spacing={1}>

                    <Rating 
                        value={(answer.numberValue || 0) / 20} 
                        precision={0.1} 
                        readOnly
                    />

                    <Box>{parseFloat(((answer.numberValue || 0) / 20).toFixed(1)).toLocaleString()}/5</Box>

                </Stack>
            }

            {answer.type === 'text' ?  
                answer.textValue :
                ''
            }

            {['yesno', 'recommendation'].includes(answer.type) ? 
                answer.numberValue === 1 ? 'OUI' : 'NON' :
                ''
            }

        </Box>

    </>
}