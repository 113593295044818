

export default function ratingCaption(value: number): string{

    if( value >= 80 ) return 'Excellent';

    if( value >= 50 ) return 'Bien';

    if( value >= 30 ) return 'Correct';

    return 'Mauvais';
}