import { Home, People, Quiz, Logout, Settings } from "@mui/icons-material"
import { Typography } from "@mui/material"
import { Link } from "react-router-dom"

type MenuOptionsProps = {
    tab: string
}


export default function MenuOptions({ tab }: MenuOptionsProps){

    return <>
    
        <Link to='/' className={`layout-menu-option ${tab === 'home' && 'layout-menu-option-on'}`}>
            <Home />
            <Typography>Dashboard</Typography>
        </Link>

        <Link to='/contacts' className={`layout-menu-option ${tab === 'contacts' && 'layout-menu-option-on'}`}>
            <People />
            <Typography>Contacts</Typography>
        </Link>

        <Link to='/quizzes' className={`layout-menu-option ${tab === 'quizzes' && 'layout-menu-option-on'}`}>
            <Quiz />
            <Typography>Formulaires</Typography>
        </Link>

        <Link to='/profile' className={`layout-menu-option ${tab === 'profile' && 'layout-menu-option-on'}`}>
            <Settings />
            <Typography>Mon compte</Typography>
        </Link>

        <Link to='/logout' className="layout-menu-option">
            <Logout />
            <Typography>Se déconnecter</Typography>
        </Link>
    
    </>
}