import background from "../../assets/img/background.jpg"
import  "../../assets/css/landing.css"
import Logo from "../../layouts/Logo"
import { Alert, Box, Rating, Stack, Typography } from "@mui/material"
import { FmdGood } from "@mui/icons-material"
import { useEffect, useState } from "react"
import { Review } from "../../models"
import API from "../../services/API"
import { useParams } from "react-router-dom"
import Loader from "../../components/ui/Loader"
import Histogram from "../../components/ui/Histogram"
import ratingAverage from "../../services/ratingAverage"
import ReviewCard from "./ReviewCard"
import ratingCaption from "../../services/ratingCaption"

type Data = {
    name: string,
    reviews: Review[],
    logo: string|null
}

export default function Index(){

    const {uuid} = useParams()

    const [status, setStatus] = useState<'loading' | 'ready'>('loading')

    const [data, setData] = useState<Data>({name: '', reviews: [], logo: null})

    useEffect(() => {

        API.get(`/companies/${uuid}`)
            .then(r => {
                setData(r.data)
                setStatus('ready')
            })

    }, [uuid])


    if(status === 'loading') return <Loader />

    return (

        <main className="landing" style={{backgroundImage: `url(${background})`}}>

            <header className="landing-header">
                <Logo />
            </header>

            <section className="landing-company">

                <Stack direction="row" spacing={3}>

                    {data.logo ?
                        <Box>
                            <img
                                src={data.logo}
                                alt="Logo"
                                width={150}
                            />
                        </Box>
                        :
                        <div className="landing-place">
                            <FmdGood color="secondary" sx={{fontSize: 100, opacity: 0.7}} />
                        </div>
                    }

                    <Stack spacing={1}>

                        <Typography variant="h1"><b>{data.name}</b></Typography>

                        <Typography color="text.secondary">
                            {data.reviews.length.toLocaleString()} avis •  {ratingCaption(ratingAverage(data.reviews) * 20)}
                        </Typography>

                        <Stack direction="row" alignItems="center" spacing={1}>

                            <Rating 
                                value={ratingAverage(data.reviews)} 
                                precision={0.1}
                                readOnly
                                size='large'
                            />

                            <Typography color="text.secondary">
                                {ratingAverage(data.reviews).toLocaleString()} / 5
                            </Typography>

                        </Stack>

                        <Alert>Entreprise vérifiée</Alert>

                    </Stack>

                </Stack>

                <Box sx={{my: 2}}>
                    <Histogram reviews={data.reviews} />
                </Box>

            </section>

            <section className="landing-reviews">

                {data.reviews
                    //.sort((a: Review, b: Review) => moment(b.createdAt).diff(moment(a.createdAt))
                    .map(r => 
                        <ReviewCard 
                            key={r.id}
                            review={r}
                        />    
                    )
                }

            </section>


        </main>
    )
}

