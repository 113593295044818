import { Box, Stack, Tab } from "@mui/material";
import Layout from "../../layouts/Layout";
import { useEffect, useState } from "react";
import API from "../../services/API";
import { useParams } from "react-router-dom";
import { Question, Quiz, emptyQuiz } from "../../models";
import Questions from "./Questions";
import { DesignServices, Quiz as QuizIcon } from "@mui/icons-material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import QuizForm from "./QuizForm";

export default function Design(){

    const {id} = useParams()

    const [status, setStatus] = useState<'loading' | 'ready' | 'error'>('loading')

    const [tab, setTab] = useState<'quiz' | 'questions'>('quiz')

    const [quiz, setQuiz] = useState<Quiz | null>(null)

    const [questions, setQuestions] = useState<Question[]>([])

    useEffect(() => {

        if(id !== 'new'){

            API.get(`/quizzes/${id}`)
                .then(r => {
                    setQuiz(r.data)
                    setQuestions(r.data?.questions || [])
                    setStatus('ready')
                })
                .catch(e => {
                    console.error(e?.data)
                    setStatus('error')
                })

        }else{

            setQuiz(emptyQuiz)
            setStatus('ready')
        }

    }, [id])

    return <Layout tab='quizzes' title="Éditer le formulaire" backLink='/quizzes' loading={status === 'loading'}>

        <TabContext value={tab}>

            <Stack sx={{maxWidth: 800, margin: 'auto'}}>

                <Box sx={{borderBottom: 1, borderColor: 'divider', mb: 2}}>

                    <TabList onChange={(_, value) => setTab(value)}>

                        <Tab label="Formulaire" value='quiz' icon={<DesignServices />} iconPosition="start"/>

                        {id !== 'new' &&
                            <Tab label="Questions" value='questions' icon={<QuizIcon />} iconPosition="start"/>
                        }

                    </TabList>

                </Box>

                <TabPanel value="quiz">

                    {quiz && 
                        <QuizForm
                            quiz={quiz}
                            onChange={quiz => setQuiz(quiz)}
                        />
                    }

                </TabPanel>

                <TabPanel value="questions">

                    <Questions
                        questions={questions}
                        onChange={questions => setQuestions(questions)}
                    />

                </TabPanel>

            </Stack>

        </TabContext>

    </Layout>
}