import { Stack, TextField as Field } from "@mui/material";
import { Quiz } from "../../models";
import { useState } from "react";
import File from "../../components/form/File";
import { LoadingButton } from "@mui/lab";
import API from "../../services/API";
import { useDispatch } from "react-redux";
import { setToast } from "../../redux/toastSlice";
import { useNavigate } from "react-router-dom";

type QuizFormProps = {
    quiz: Quiz,
    onChange: (quiz: Quiz) => void
}

export default function QuizForm({quiz, onChange}: QuizFormProps){

    const navigate = useNavigate()

    const dispatch = useDispatch()

    const [data, setData] = useState<Quiz>(quiz)

    const [loading, setLoading] = useState<boolean>(false)

    const [uploading, setUploading] = useState<boolean>(false)

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()

        setLoading(true)

        saveQuiz(data)
            .then(r => {

                dispatch(setToast({
                    message: "Vos modifications ont bien été enregistrées.",
                    severity: 'success'
                }))

                return quiz.id ? onChange(r.data) : navigate(`/quizzes/${r.data?.id}`)

            })
            .catch(_ => dispatch(setToast({
                message: "Une erreur s'est produite.",
                severity: 'error'
            })))
            .finally(() => setLoading(false))
    }

    const handleLogoChange = (file: File | null) => {

        if(file){

            setUploading(true)

            const formData = new FormData()
            formData.append('file', file)

            API.post(`/files`, formData)
                .then(r => {

                    setData({...data, logo: r.data.name})

                    dispatch(setToast({
                        message: "Votre logo a bien été enregistré.",
                        severity: 'success'
                    }))
                })
                .catch(e => {

                    dispatch(setToast({
                        message: "Une erreur s'est produite.",
                        severity: 'error'
                    }))
                })
                .finally(() => {

                    setUploading(false)
                })
        
        }else{

            setData({...data, logo: null})
        }
        
    }

    return <form onSubmit={handleSubmit}>
    
        <Stack spacing={4} sx={{maxWidth: 500, margin: 'auto'}}>

            <Field 
                label="Titre"
                value={data.title}
                onChange={e => setData({...data, title: e.target.value})}
                required 
                inputProps={{minLength: 2, maxLength: 200}}
                size='small'
            />

            <Stack direction='row' spacing={1}>

                <Field 
                    label="Couleur principale"
                    type='color'
                    value={data.mainColor}
                    onChange={e => setData({...data, mainColor: e.target.value})}
                    required 
                    size='small'
                    sx={{flex: 1}}
                />

                <Field 
                    label="Cour de fond"
                    type='color'
                    value={data.backgroundColor}
                    onChange={e => setData({...data, backgroundColor: e.target.value})}
                    required 
                    size='small'
                    sx={{flex: 1}}
                />

            </Stack>

            {false && 
                <File 
                    id='quiz-logo'
                    label="Ajoutez un logo"
                    accept={['png','jpg','jpeg','svg']}
                    onChange={handleLogoChange}
                    url={data.logo ? API.url(`/files/${data.logo}`) : null}
                    loading={uploading}
                    image
                />
            }

            <LoadingButton
                type='submit'
                variant='contained'
                loading={loading}
            >
                Valider
            </LoadingButton>

        </Stack>

    </form> 
}


const saveQuiz = async (quiz: Quiz) => {

    return quiz.id ? 
        await API.put(`/quizzes/${quiz.id}`, quiz) :
        await API.post(`/quizzes`, quiz)
}