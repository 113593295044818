import { Stack, Typography } from "@mui/material";
import { useEffect, useMemo, useState } from "react"
import API from "../../services/API";
import { useParams } from "react-router-dom";
import { Question, Quiz, Review } from "../../models";
import Thanks from "./Thanks";
import { createTheme, ThemeProvider, Theme } from '@mui/material/styles'
import generateMUITheme from "../../services/generateMUITheme";
import Logo from "./Logo";
import { Answer } from "../../models/Answer";
import Content from "./Content";
import Redirect from "./Redirect";

type Status = 'loading' | 'ready' | 'submit' | 'completed' | 'thanks' | 'redirect'

export default function Index(){

    const {uuid} = useParams()

    const [status, setStatus] = useState<Status>('loading')

    const [theme, setTheme] = useState<Theme>(createTheme())

    const [quiz, setQuiz] = useState<Quiz | null>(null)

    const [review, setReview] = useState<Review | null>(null)

    const [questions, setQuestions] = useState<Question[]>([])

    const [index, setIndex] = useState<number>(0)

    const [answer, setAnswer] = useState<Answer | null>(null)

    const question = useMemo(() => questions[index], [questions, index]) // Current question

    useEffect(() => {

        API.get(`/form/${uuid}`)
            .then(r => {
                setQuiz(r.data.quiz)
                setReview(r.data.review)
                setQuestions(r.data.questions)
                setTheme(generateMUITheme(r.data.quiz.mainColor, r.data.quiz.backgroundColor))
                setStatus('ready')
            })

    }, [uuid])


    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        setStatus('submit')

        API.post(`/reviews/${review?.uuid}/answers`, answer)
            .then(_ => {

                if(question.redirectUrl && (answer?.numberValue || 0) >= (question.redirectThreshold || 100) ){

                    setStatus('redirect')

                }else if( !questions[index + 1] ){

                    setStatus('thanks')

                }else{

                    setIndex(index + 1)
                    setStatus('ready')
                }

                setAnswer(null)
            })
            .catch(e => {
                console.error(e.response?.data?.error)
                setStatus('ready')
            })
    }


    if(status === 'loading') return <></>


    return <ThemeProvider theme={theme}>
    
        <form onSubmit={handleSubmit} className='layout-center' style={{backgroundColor: theme.palette.secondary.main}}>


            <Stack spacing={3} sx={formStyle}>

                <Logo fileName={quiz?.logo} />

                <Typography variant='h3'>{quiz?.title}</Typography>

                {(question && ['ready','submit'].includes(status)) && 

                    <Content 
                        question={question}
                        answer={answer}
                        onAnswerChange={setAnswer}
                        loading={status === 'submit'}
                    />

                }

                {status === 'redirect' &&
                    <Redirect
                        uuid={review?.uuid}
                        url={question.redirectUrl || window.location.href} 
                    />
                }

                {status === 'thanks' &&
                    <Thanks uuid={review?.uuid} />
                }

            </Stack>

        </form>

    </ThemeProvider>
}



const formStyle = {
    backgroundColor: 'white',
    width: window.innerWidth < 600 ? '100%' : '500px', 
    p: '20px',
    borderRadius: '5px',
    border: 'solid 1px lightgrey'
}
