import { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import API from "../services/API";
import type { RootState } from '../redux/store';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { setUser } from '../redux/userSlice';
import Loader from "../components/ui/Loader";


type PrivateProps = {
    children: React.ReactNode
}


export default function Private({children}: PrivateProps){

    const user = useSelector((state: RootState) => state.user.value)

    const dispatch = useDispatch()

    const [status, setStatus] = useState<'loading' | 'success' | 'error' | 'redirect'>('loading')

    const [redirect, setRedirect] = useState<string>('/login')

    useEffect(() => {

        if(API.hasAccessToken() && user){
            
            setStatus('success')
        
        }else{

            API.get('/me')
                .then(r => {
                    dispatch(setUser(r.data))
                    setStatus('success')
                })
                .catch(e => {
                    
                    /* Payment required */
                    if(e.response.status === 402){
                        setRedirect(`/pay/${e.response.data.subscription}`)
                    }

                    setStatus('redirect')
                })
        }

    }, [dispatch, user])


    if(status === 'loading'){

        return <Loader />

    }
    
    if(status === 'redirect'){

        return <Navigate to={redirect} />

    }

    return <>{children}</>
}