import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, Stack, TextField } from "@mui/material"
import { Contact, Quiz } from "../../models";
import { LoadingButton } from "@mui/lab";
import { useEffect, useState } from "react";
import API from "../../services/API";
import { setToast } from "../../redux/toastSlice";
import Loader from "../../components/ui/Loader";
import { Send } from "@mui/icons-material";
import { useDispatch } from "react-redux";

type SendFormProps = {
    contacts: Contact[],
    open: boolean,
    onClose: () => void
}


type Mode = 'email' | 'sms';

export default function SendQuiz({contacts, open, onClose}: SendFormProps){

    const dispatch = useDispatch()

    const [quizzes, setQuizzes] = useState<Quiz[]>([])

    const [quizId, setQuizId] = useState<number | null>(null)

    const [status, setStatus] = useState<'loading' | 'ready' | 'error' | 'submit'>('loading')

    const [mode, setMode] = useState<Mode>('email')

    
    useEffect(() => {

        setStatus('loading')

        API.get('/company/quizzes')
            .then(r => {
                setQuizzes(r.data)
                setQuizId(r.data[0]?.id || null)
                setStatus('ready')
            })
            .catch(_ => setStatus('error'))

    }, [])


    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()

        setStatus('submit')

        API.post(`/quizzes/${quizId}/send/${mode}`, contacts)
            .then(r => {

                setStatus('ready')

                onClose()

                dispatch(setToast({
                    severity: 'success',
                    message: `${contacts.length} demandes d'avis ont été envoyées.`
                }))

            })
            .catch(e => {
                setStatus('error')
            })

    }


    return <Dialog open={open} onClose={onClose}>

        <DialogTitle>Envoi du formulaire</DialogTitle>

        <form onSubmit={handleSubmit}>

            <DialogContent>

                <Stack spacing={3} sx={{width: window.innerWidth > 600 ? 500 : 300}}>

                    {status === 'error' && 
                        <Alert severity='error'>Une erreur s'est produite.</Alert>
                    }

                    {status === 'loading' &&
                        <Loader />
                    }

                    {['ready','submit','error'].includes(status) && <>

                        <TextField
                            select
                            label='Sélectionner le formulaire à envoyer'
                            value={quizId || ''}
                            onChange={e => setQuizId(parseInt(e.target.value))}
                            required
                            size='small'
                        >
                            {quizzes.map(q => 
                                <MenuItem key={q.id} value={q.id}>{q.title}</MenuItem>    
                            )}
                        </TextField>

                        <TextField
                            select
                            label="Mode d'envoi"
                            value={mode}
                            onChange={e => setMode(e.target.value as Mode)}
                            required
                            size='small'
                        >
                            <MenuItem value="email">Email</MenuItem>
                            <MenuItem value="sms">SMS</MenuItem>
                        </TextField>

                    </>}
                    

                </Stack>

            </DialogContent>

            <DialogActions>

                <Button onClick={onClose}>Annuler</Button>

                <LoadingButton
                    type='submit'
                    loading={status === 'submit'}
                    variant='contained'
                    startIcon={<Send />}
                >
                    Envoyer
                </LoadingButton>

            </DialogActions>

        </form>

    </Dialog>
}