import { Api, Code, Public, Send, Share, Support } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary, Button, Stack, Typography } from "@mui/material";
import { GridExpandMoreIcon } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import API from "../../services/API";
import { Company } from "../../models";
import ShareModal from "../../components/ui/ShareModal";
import localeUrl from "../../services/localeUrl";
import Loader from "../../components/ui/Loader";
import ApiKey from "./ApiKey";


export default function Settings(){

    const [status, setStatus] = useState<'loading' | 'ready'>('loading')

    const [company, setCompany] = useState<Company | null>(null)

    const [shareModal, setShareModal] = useState<boolean>(false)

    useEffect(() => {

        API.get(`/company`)
            .then(r => {
                setCompany(r.data)
                setStatus('ready')
            })
    }, [])


    if(status === 'loading') return <Loader />

    return <>

        <Stack>

            <Accordion>

                <AccordionSummary expandIcon={<GridExpandMoreIcon />}>

                    <Stack direction="row" alignItems="center" spacing={2}>

                        <Support color="action" />
                        
                        <Typography>Contacter le support</Typography>

                    </Stack>

                </AccordionSummary>

                <AccordionDetails>

                    <Stack spacing={2}>

                        <span>Vous rencontrez un problème dans l'utilisation de la plateforme ou vous avez une question sur votre abonnement ?</span>

                        <Button href="mailto:contact@reviewlib.com" startIcon={<Send />}>
                            Contacter l'assistance
                        </Button>
                    
                    </Stack>


                </AccordionDetails>

            </Accordion>

            <Accordion>

                <AccordionSummary expandIcon={<GridExpandMoreIcon />}>

                    <Stack direction="row" alignItems="center" spacing={2}>

                        <Public color="action" />
                        
                        <Typography>Partager mes avis</Typography>

                    </Stack>

                </AccordionSummary>

                <AccordionDetails>

                    <Stack spacing={2}>

                        <span>Diffusez vos expériences via votre <Link to={`/experience/${company?.uuid}`}><u>espace dédié</u></Link> sur ReviewLib.</span>

                        <Button onClick={() => setShareModal(true)} startIcon={<Share />}>
                            Partager la page
                        </Button>

                    </Stack>
                    
                </AccordionDetails>

            </Accordion>

            <Accordion>

                <AccordionSummary expandIcon={<GridExpandMoreIcon />}>

                    <Stack direction="row" alignItems="center" spacing={2}>

                        <Code color="action" />
                        
                        <Typography>Intégrer dans mon site</Typography>

                    </Stack>

                </AccordionSummary>

                <AccordionDetails>

                    <Stack spacing={2}>

                        <span>Embarquez le widget ReviewLib dans votre site internet pour partager vos avis.</span>

                        <textarea
                            value={`<iframe title="Avis ReviewLib" src="${localeUrl(`/embed/${company?.uuid}`)}" width="500" height="300" frameborder="0"></iframe>`}
                            style={{height: 80, padding: 10, backgroundColor: 'black', color: 'white', border: 'none'}}
                            spellCheck={false}
                        />

                    </Stack>
                    
                </AccordionDetails>

            </Accordion>

            <Accordion>

                <AccordionSummary expandIcon={<GridExpandMoreIcon />}>

                    <Stack direction="row" alignItems="center" spacing={2}>

                        <Api color="action" />
                        
                        <Typography>Utiliser l'API</Typography>

                    </Stack>

                </AccordionSummary>

                <AccordionDetails>
                    
                <Stack spacing={2}>

                    <span>Automatisez vos demandes d'avis grâce à l'API ReviewLib.</span>

                    <textarea
                        value={`curl -X POST "https://api.reviewlib.com/feedback" \\ \n -H "Content-Type: application/json" \\ \n -H "Authorization: Bearer MY_API_KEY" \\ \n -d '{ \n        "firstname": "Jeanne", \n        "lastname": "Martin", \n        "email": "jeanne.martin@exemple.com", \n        "phone": "+33601234567", \n        "form": "MY_FORM_ID", \n        "mode": "email" \n     }'`}
                        style={{height: 180, padding: 10, backgroundColor: 'black', color: 'white', border: 'none'}}
                        spellCheck={false}
                    />

                    <ApiKey />

                </Stack>

                    
                </AccordionDetails>

            </Accordion>

        </Stack>

        <ShareModal
            open={shareModal}
            onClose={() => setShareModal(false)}
            link={localeUrl(`/experience/${company?.uuid}`)}
        />
        
    </>
}