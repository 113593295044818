import { Stack, Typography as Typo, Alert, Box } from "@mui/material";
import { useState } from "react";
import { LoadingButton } from '@mui/lab';
import API from "../../services/API";
import Password from "../../components/form/Password";
import { Link, useParams } from "react-router-dom";

export default function ResetPassword(){

    const {key} = useParams()

    const [password, setPassword] = useState('')

    const [status, setStatus] = useState<'ready' | 'submit' | 'success' | 'error'>('ready')

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {

        event.preventDefault()

        setStatus('submit')

        API.put('/password/reset', {password, key})
            .then(_ => setStatus('success'))
            .catch(_ => setStatus('error'))
    }

    return <form onSubmit={handleSubmit} className='layout-center'>

        <Box sx={{py: 3, px: 2, width}}>

            <Stack spacing={3}>

                <Typo variant='h2'>Réinitialiser le mot de passe</Typo>

                {status === 'error' &&
                    <Alert severity='error'>
                        Votre lien de réinitialisation de mot de passe a expiré.
                    </Alert>
                }

                {status === 'success' &&
                    <Alert severity='success'>
                        Votre mot de passe a été réinitialisé. Veuillez <Link to="/login"><u>vous reconnecter</u></Link>.
                    </Alert>
                }

                {status !== 'success' && <>

                    <Password
                        label="Nouveau mot de passe"
                        value={password}
                        onChange={e => setPassword(e.target.value)}
                    />


                    <LoadingButton
                        loading={status === 'submit'}
                        variant='contained'
                        type='submit'
                    >
                        Valider
                    </LoadingButton>

                </>}

            </Stack>

        </Box>

    </form>
}

const width = window.innerWidth > 800 ? 500 : 320