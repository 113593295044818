export interface Question {
    id?: number, 
    createdAt?: string,
    updatedAt?: string,
    title: string,
    rank: number,
    type: QuestionType,
    optional: boolean,
    editable: boolean,
    redirectUrl?: string | null,
    redirectThreshold?: number | null
}

export type QuestionType = 'rating' | 'stars' | 'text' | 'email' | 'yesno' | 'recommendation';

export const questionTypes: {[key in QuestionType]: string} = {
    rating: "Jauge de 1 à 10",
    stars: "Note de 1 à 5 étoiles",
    text: "Texte libre",
    yesno: "Oui/Non",
    email: "Adresse email",
    recommendation: "Recommandation"
};

export let emptyQuestion: Question = {
    title: '',
    rank: 0,
    type: 'rating',
    optional: false,
    editable: true
}