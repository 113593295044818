import { Button, FormControlLabel, MenuItem, Slider, Stack, Switch, TextField, Typography } from "@mui/material";
import { Question, QuestionType, questionTypes } from "../../models";
import { useState } from "react";
import { LoadingButton } from "@mui/lab";
import API from "../../services/API";
import { useDispatch } from "react-redux";
import { setToast } from "../../redux/toastSlice";
import { useParams } from "react-router-dom";

type QuestionFormProps = {
    question: Question
    onChange: (question: Question) => void
    onClose: () => void
}

export default function QuestionForm({question, onChange, onClose}: QuestionFormProps){

    const { id } = useParams()

    const dispatch = useDispatch()

    const [data, setData] = useState<Question>(question)

    const [loading, setLoading] = useState<boolean>(false)

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        setLoading(true)

        saveQuestion(data, id)
            .then(r => {

                console.log(r.data)

                onChange(r.data)

            })
            .catch(_ => {
                
                dispatch(setToast({
                    message: "Une erreur s'est produite.",
                    severity: 'error'
                }))

            })
            .finally(() => setLoading(false))

    }

    return  <form onSubmit={handleSubmit} style={style}>

            <Stack spacing={3}>

                <TextField
                    label="Intitulé de la question"
                    value={data.title}
                    onChange={e => setData({...data, title: e.target.value})}
                    inputProps={{maxLength: 200}}
                    required 
                    size='small'
                />

                <TextField
                    label="Type de question"
                    value={data.type}
                    onChange={(e) => setData({...data, 
                        type: e.target.value as QuestionType,
                        redirectUrl: null,
                        redirectThreshold: null
                    })}
                    select
                    required
                    size='small'
                >
                    {Object.keys(questionTypes).map((k) => (
                        <MenuItem key={k} value={k}>
                            {questionTypes[k as keyof typeof questionTypes]}
                        </MenuItem>
                    ))}
                </TextField>

                {['rating','stars'].includes(data.type) && 

                    <FormControlLabel control={
                        <Switch 
                            checked={data.redirectUrl !== null && data.redirectUrl !== undefined} 
                            onChange={(_, value) => setData({...data,
                                redirectUrl: value ? '' : null,
                                redirectThreshold: value ? 80 : null
                            })}
                        />
                    } label="Rediriger si l'avis est positif" />

                }

                {(data.redirectUrl !== null && data.redirectUrl !== undefined) && <>
                
                    <TextField
                        label="URL de redirection"
                        type="url"
                        value={data.redirectUrl || ''}
                        onChange={e => setData({...data, redirectUrl: e.target.value})}
                        inputProps={{maxLength: 250}}
                        required 
                        size='small'
                    />

                    <Stack sx={{pb: 4}} spacing={2}>

                        <Typography color="text.secondary">
                            Note au delà de laquelle la redirection a lieu :
                        </Typography>

                        <Slider
                            marks={[0,1,2,3,4,5,6,7,8,9,10].map(n => {
                                return {label: n.toString(), value: n}
                            })}
                            valueLabelDisplay="off"
                            min={0}
                            max={10}
                            step={1}
                            value={Math.round((data.redirectThreshold || 0) / 10)}
                            onChange={(_, value: any) => setData({...data, redirectThreshold: 
                                Math.round((Number.isNaN(value) ? 0 : value) * 10)
                            })}
                        />

                    </Stack>
                
                </>}

                <FormControlLabel control={
                    <Switch 
                        checked={data.optional} 
                        onChange={(_, optional) => setData({...data, optional})}
                    />
                } label="Question facultative" />

                <LoadingButton
                    variant='contained'
                    type='submit'
                    loading={loading}
                >
                    Valider
                </LoadingButton>

                <Button onClick={onClose}>
                    Annuler
                </Button>


            </Stack>

        </form>
}


const saveQuestion = async (question: Question, quizId: string | undefined) => {

    return question.id ? 
        await API.put(`/quizzes/${quizId}/questions/${question.id}`, question) :
        await API.post(`/quizzes/${quizId}/questions`, question)
}


const style = {
    maxWidth: 500, 
    margin: 'auto',
    width: window.innerWidth < 800 ? 320 : 500
}