import { Stack, Typography as Typo, useTheme} from "@mui/material";
import { Review } from "../../models";
import { useMemo } from "react";
import { AutoAwesome, People, TaskAlt, ThumbUp } from "@mui/icons-material";
import banner from "../../assets/img/background.jpg"
import Histogram from "../../components/ui/Histogram";
import ratingAverage from "../../services/ratingAverage";
import Logo from "./Logo";

type StatsProps = {
    reviews: Review[]
}

export default function Stats({reviews}: StatsProps){

    const theme = useTheme()

    const ratingAvg = useMemo(() => {
        let items = reviews.filter(r => r.status === 'completed')
        return ratingAverage(items)
    }, [reviews])


    const completionRate = useMemo(() => {
        let items = reviews.filter(r => r.status === 'completed')
        let count = items.length
        let total = reviews.length
        let rate = total > 0 ? (count / total * 100) : 0
        return Math.round(rate)
    }, [reviews])


    const recommendationRate = useMemo(() => {
        let items = reviews.filter(r => r.status === 'completed')
        let count = items.filter(r => r.recommended)?.length
        let total = items.length
        let rate = total > 0 ? (count / total * 100) : 0
        return Math.round(rate)
    }, [reviews])


    const npsScore = useMemo(() => {
        let items = reviews.filter(r => r.status === 'completed')
        let all = items.length
        let promoters = items.filter(i => i.rating >= 90).length
        let promotersRatio = all > 0 ? (promoters / all * 100) : 0
        let detractors = items.filter(i => i.rating < 70).length
        let detractorsRatio = all > 0 ? (detractors / all * 100) : 0
        return Math.round(promotersRatio - detractorsRatio)
    }, [reviews])
    

    return (

        <>

            <Stack direction='row' justifyContent='space-around' alignItems='center' flexWrap='wrap' sx={style}>

                <Logo />

                <Stack spacing={3.5}>

                    <Stack direction='row' flexWrap='wrap'>

                        <Stack alignItems='center' spacing={1} sx={kpiStyle}>
                            <Stack direction='row' spacing={2} alignItems='center'>
                                <AutoAwesome color='primary' sx={{fontSize: 30}} />
                                <Typo variant='h3' color={theme.palette.primary.main}>
                                    <b>{ratingAvg.toLocaleString()} / 5</b>
                                </Typo>
                            </Stack>
                            <Typo color='text.secondary' variant='body2'>Note moyenne</Typo>
                        </Stack>

                        <Stack alignItems='center' spacing={1} sx={kpiStyle}>
                            <Stack direction='row' spacing={2} alignItems='center'>
                                <People color='primary' sx={{fontSize: 30}} />
                                <Typo variant='h3' color={theme.palette.primary.main}>
                                    <b>{reviews.length.toLocaleString()}</b>
                                </Typo>
                            </Stack>
                            <Typo color='text.secondary' variant='body2'>Avis reçus</Typo>
                        </Stack>

                    </Stack>

                    <Stack direction='row' flexWrap='wrap'>

                        <Stack alignItems='center' spacing={1} sx={kpiStyle}>
                            <Stack direction='row' spacing={2} alignItems='center'>
                                <ThumbUp color='primary' sx={{fontSize: 30}} />
                                <Typo variant='h3' color={theme.palette.primary.main}>
                                    <b>{recommendationRate.toLocaleString()}%</b>
                                </Typo>
                            </Stack>
                            <Typo color='text.secondary' variant='body2'>Recommandation</Typo>
                        </Stack>

                        {false && <Stack alignItems='center' spacing={1} sx={kpiStyle}>
                            <Stack direction='row' spacing={2} alignItems='center'>
                                <TaskAlt color='primary' sx={{fontSize: 30}} />
                                <Typo variant='h3' color={theme.palette.primary.main}>
                                    <b>{completionRate}</b> <small>%</small>
                                </Typo>
                            </Stack>
                            <Typo color='text.secondary' variant='body2'>Avis complétés</Typo>
                        </Stack>}

                        <Stack alignItems='center' spacing={1} sx={kpiStyle}>
                            <Stack direction='row' spacing={2} alignItems='center'>
                                <TaskAlt color='primary' sx={{fontSize: 30}} />
                                <Typo variant='h3' color={theme.palette.primary.main}>
                                    <b>{npsScore}</b> <small></small>
                                </Typo>
                            </Stack>
                            <Typo color='text.secondary' variant='body2'>Score NPS</Typo>
                        </Stack>

                    </Stack>

                </Stack>

                <Histogram reviews={reviews.filter(r => r.status === 'completed')} />

            </Stack>

        </>
    )
}

const style = {
    py: 3,
    borderRadius: '5px',
    boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
    border: 'solid 1px rgb(250,250,250)',
    backgroundImage: `url(${banner})`,
    backgroundSize: 'cover'
}

const kpiStyle = {
    width: 120,
    mx: 3,
}