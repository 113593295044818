import { Button, Stack } from "@mui/material";
import Subscription from "./Subscription";

export const subscriptionKeys = ['essentiel', 'harmonie', 'groupe'];

type SubscriptionsProps = {
    options?: string[],
    onSelect: (value: string) => void
}

export default function Subscriptions({options = subscriptionKeys, onSelect}: SubscriptionsProps){

    return <Stack direction='row' flexWrap='wrap'>
    
        {options.includes('essentiel') &&

            <Subscription 
                title="Essentiel" 
                description={
                    <><b>Récoltez</b> et <b>analysez </b>simplement vos avis patients</>
                }
                options={[
                    "Récolte illimitée d'avis",
                    "Accès aux questionnaires spécifiques à votre profession, totalement personnalisables",
                    "Tableau de bord de pilotage complet",
                    "Attestation de démarche qualité",
                    "Système de modération des avis",
                    "Avis publiés sur ReviewLib.com",
                    "Diffusion des avis sur deux plateformes externes de votre choix (Google, réseaux sociaux)",
                    "Widget d'avis intégré sur votre site internet",
                    "Partage facile sur les réseaux sociaux",
                    "Assistance back office",
                ]}
                button={
                    <Button variant='contained' onClick={() => onSelect('essentiel')}>
                        Sélectionner
                    </Button>
                } 
                elevation={1}
            />
        }

        {options.includes('harmonie') &&

            <Subscription 
                title="Harmonie" 
                description={<>
                    Récoltez, analysez, <b>diffusez</b> vos avis et <b>optimisez</b> votre démarche qualité
                </>}
                optionsTitle={<>
                    Tous les avantages du forfait <b>Essentiel</b>, plus :
                </>}
                options={[
                    "Pack communication complet incluant affiches, QR codes, certificats, livres d'or, flyers, etc.",
                    "Diffusion des avis sur toutes vos plateformes (Google, Pages Jaunes, réseaux sociaux…)",
                    "Questionnaires disponibles en multilingue",
                    "Système de traduction des avis",
                    "Clé API personnalisable pour une intégration avancée",
                    "Newsletter personnalisable",
                    "Mesurez votre NPS (Net Promoter Score)",
                    "Assistance back office prioritaire",
                ]}
                button={
                    <Button variant='contained' onClick={() => onSelect('harmonie')}>
                        Sélectionner
                    </Button>
                } 
                elevation={4}
            />
        }

        {options.includes('groupe') &&

            <Subscription 
                title="Groupe" 
                description={<>
                    <b>Suivez</b> les performances de l'ensemble de votre réseau
                </>}
                optionsTitle={<>
                    Tous les avantages du forfait <b>Harmonie</b>, plus :
                </>}
                options={[
                    "Compte superviseur pour suivre l'ensemble de vos entités",
                    "Gamification des performances du réseau",
                ]}
                button={
                    <Button variant='contained' href="https://www.reviewlib.com/#contact">
                        Contactez-nous
                    </Button>
                } 
                elevation={1}
            />

        }
    
    </Stack>
}
