import { Button, IconButton } from "@mui/material";
import Layout from "../../layouts/Layout";
import { useState, useEffect } from "react";
import API from "../../services/API";
import moment from "moment";
import { Add, Delete, Edit,  Visibility, Share, QrCode2 } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { GridColDef } from '@mui/x-data-grid';
import Grid from "../../components/ui/Grid";
import { useDispatch } from "react-redux";
import { setToast } from "../../redux/toastSlice";
import { Quiz } from "../../models";
import localeUrl from "../../services/localeUrl";
import qrCodeUrl from "../../services/qrCodeUrl";
import ShareModal from "../../components/ui/ShareModal";

export default function Index(){

    const dispatch = useDispatch()

    const [quizzes, setQuizzes] = useState<Quiz[]>([])

    const [share, setShare] = useState<string | null>(null)

    const [status, setStatus] = useState<'loading' | 'ready' | 'error'>('loading')

    useEffect(() => {

        API.get('/company/quizzes')
            .then(r => {
                setQuizzes(r.data)
                setStatus('ready')
            })
            .catch(e => {
                console.error(e?.data)
                setStatus('error')
            })

    }, [])

    const handleDelete = (id: number): boolean => {
        
        if(!window.confirm("Êtes-vous sûr de vouloir supprimer ce formulaire ?")) return false

        const old = [...quizzes];
        let copy = [...quizzes].filter(i => i.id !== id)
        setQuizzes(copy)

        API.delete(`/quizzes/${id}`)
            .then(_ => {
                dispatch(setToast({message: "Le formulaire a été supprimé.", severity: "info"}))
            })
            .catch(_ => setQuizzes(old))

        return true
    }

    
    const columns: GridColDef[] = [
        {
            field: 'title',
            headerName: 'Titre',
            width: 200,
        },
        {
            field: 'createdAt',
            headerName: 'Créé le',
            width: 120,
            type: 'date',
            valueGetter: p => new Date(p.row.createdAt),
            renderCell: p => moment(p.row.createdAt).format('DD/MM/YYYY')
        },
        {
            field: 'show',
            headerName: 'Afficher',
            width: 80,
            valueGetter: p => p.row.id,
            renderCell: p =>
                <IconButton 
                    component={Link} 
                    to={`/form/${p.row.uuid}`} 
                    size='small'
                    target='_blank'
                    rel='noopener noreferrer'
                >
                    <Visibility />
                </IconButton>
        },
        {
            field: 'share',
            headerName: 'Partager',
            width: 80,
            valueGetter: p => p.row.uuid,
            renderCell: p =>
                <IconButton size='small' onClick={() => setShare(localeUrl(`/form/${p.value}`))}>
                    <Share />
                </IconButton>
        },
        {
            field: 'qrcode',
            headerName: 'QR Code',
            width: 80,
            valueGetter: p => p.row.uuid,
            renderCell: p =>
                <IconButton 
                    size='small' 
                    href={qrCodeUrl(localeUrl(`/form/${p.value}`))} 
                    rel='noopener noreferrer' 
                    target='_blank'
                >
                    <QrCode2 />
                </IconButton>
        },
        {
            field: 'edit',
            headerName: 'Editer',
            width: 80,
            valueGetter: p => p.row.id,
            renderCell: p =>
                <IconButton component={Link} to={`/quizzes/${p.value}`} size='small'>
                    <Edit />
                </IconButton>
        },
        {
            field: 'delete',
            headerName: 'Supprimer',
            width: 100,
            valueGetter: p => p.row.id,
            renderCell: p =>
                <IconButton onClick={() => handleDelete(p.value)}>
                    <Delete />
                </IconButton>
        },
        {
            field: 'uuid',
            headerName: 'Identifiant API',
            width: 350,
        },
    ]

    return <Layout tab='quizzes' title="Formulaires">

        <Grid 
            columns={columns}
            rows={quizzes}
            loading={status === 'loading'}
            pageSize={10}
            toolbar={<>
                <Button 
                    startIcon={<Add />}
                    component={Link}
                    to='/quizzes/new'
                >
                    Créer un formulaire
                </Button>
            </>}
        />

        <ShareModal
            link={share || ''}
            title="Partager le formulaire"
            open={share ? true : false}
            onClose={() => setShare(null)}
        />

    </Layout>
}




