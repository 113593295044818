import { Avatar, Stack, Typography as Typo } from '@mui/material'
import type { RootState } from '../../redux/store'
import { useSelector } from 'react-redux'
import { useTheme } from '@mui/material/styles'
import initials from '../../services/initials'

export default function User(){

    const theme = useTheme()

    const user = useSelector((state: RootState) => state.user.value)

    return user && 

        <Stack direction='row' spacing={1} alignItems='center' sx={{m: 2}}>
            
            <Avatar sx={{bgcolor: theme.palette.primary.main, width: 45, height: 45}}>
                {initials(user.firstname, user.lastname)}
            </Avatar>

            <Typo><small>{user.firstname + ' ' + user.lastname}</small></Typo>

        </Stack>

}