import { TextField } from "@mui/material";
import { ChangeEvent } from "react";

type PhoneProps = {
    value: string
    onChange: (event: ChangeEvent<HTMLInputElement>) => void,
    required?: boolean
};

export default function Phone({ value, onChange, required }: PhoneProps) {

    return (
        <TextField
            label='Téléphone'
            size='small'
            value={value}
            onChange={onChange}
            helperText='Exemple : +33601234567'
            inputProps={{
                pattern: "\\+[0-9]+",
                minLength: 7,
                maxLength: 15
            }}
            required={required ? true : false}
        />
    )

}
