import { Button, IconButton, Paper, Stack, Typography as Typo } from "@mui/material";
import { Question, emptyQuestion } from "../../models";
import { Add, ArrowDownward, ArrowUpward, Delete, Edit } from "@mui/icons-material";
import { useState } from "react";
import QuestionForm from "./QuestionForm";
import { useParams } from "react-router-dom";
import API from "../../services/API";

type QuestionsProps = {
    questions: Question[],
    onChange: (questions: Question[]) => void
}

export default function Questions({questions, onChange}: QuestionsProps){

    const {id} = useParams()

    const [form, setForm] = useState<Question | null>(null)


    const handleDelete = (question: Question) => {

        if(!window.confirm("Êtes-vous sûr de vouloir supprimer cette question ?")) return false;

        const old = [...questions]

        let copy = [...questions].filter(q => q.id !== question.id).map((q, i) => {
            return {...q, rank: i + 1}
        })

        onChange(copy)

        API.delete(`/quizzes/${id}/questions/${question.id}`)
            .catch(_ => onChange(old))
    }


    const handleEdit = (question: Question) => {
        let copy = [...questions].filter(q => q.id !== question.id)
        copy.push(question)
        onChange(copy)
        setForm(null)
    }

    const handleMove = (question: Question, value: number) => {

        const oldQuestions = [...questions];

        let newQuestions = [...questions]
            .map((q,i) => {
                let rank = q.id === question.id ? (i + 1 + value) : (i + 1);
                return {...q, rank}
            })
            .sort((a,b) => a.rank - b.rank)
            .map((q, i) => {
                return {...q, rank: i + 1}
            })

        onChange(newQuestions)

        API.put(`/quizzes/${id}/order`, newQuestions)
            .catch(_ => onChange(oldQuestions))
    }


    return <Stack spacing={2}>

        {!form && questions
            .sort((a,b) => a.rank - b.rank)
            .map((q,n) => 
                <Item 
                    key={n}
                    question={q}
                    rank={n + 1}
                    maxRank={questions.length}
                    onEdit={setForm}
                    onDelete={handleDelete}
                    onMove={handleMove}
                />    
            )
        }


        {!form && 
            <Button 
                startIcon={<Add />}
                onClick={() => setForm({...emptyQuestion, rank: questions.length + 1})}
            >
                Ajouter une question
            </Button>
        }


        {form && 
            <QuestionForm 
                question={form}
                onChange={handleEdit}
                onClose={() => setForm(null)}
            />
        }

    </Stack>
}



type ItemProps = {
    question: Question,
    rank: number,
    maxRank: number,
    onEdit: (question: Question) => void,
    onDelete: (question: Question) => void,
    onMove: (question: Question, value: number) => void,
}

function Item({question, rank, maxRank, onEdit, onDelete, onMove}: ItemProps){

    return <Paper variant='outlined' sx={{ p: 2 }}>

        <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={1}>

            <Stack direction='row'>

                <Typo sx={{width: 30}}>{rank}.</Typo>

                <Typo>{question.title}</Typo>

            </Stack>

            <Stack direction='row'>

                <IconButton onClick={() => onEdit(question)} size='small'>
                    <Edit />
                </IconButton>

                <IconButton onClick={() => onDelete(question)} size='small'>
                    <Delete />
                </IconButton>

                <IconButton onClick={() => onMove(question, 1.5)} disabled={rank === maxRank} size='small'>
                    <ArrowDownward/>
                </IconButton>

                <IconButton onClick={() => onMove(question, -1.5)} disabled={rank === 1} size='small'>
                    <ArrowUpward/>
                </IconButton>

            </Stack>

        </Stack>

    </Paper> 
}