import { useEffect, useState } from "react"
import Layout from "../../layouts/Layout"
import Subscriptions, { subscriptionKeys } from "./Subscriptions"
import { Company } from "../../models"
import API from "../../services/API"
import { Alert } from "@mui/material"
import { useDispatch } from "react-redux"
import { setToast } from "../../redux/toastSlice"
import { Navigate } from "react-router-dom"
import Loader from "../../components/ui/Loader"

export default function Upgrade(){

    const [company, setCompany] = useState<Company | null>(null)

    const [status, setStatus] = useState<'loading' | 'ready' | 'error' | 'success'>('loading')

    const dispatch = useDispatch()

    useEffect(() => {

        API.get('/company')
            .then(r => {
                setCompany(r.data)
                setStatus('ready')
            })

    }, [])

    const handleSelect = (subscription: string) => {

        setStatus('loading')

        API.get(`/subscriptions/${subscription}/pay`)
            .then(r => {

                if(r.data.paymentUrl){

                    window.location.href = r.data.paymentUrl

                }else{

                    dispatch(setToast({
                        message: "Votre abonnement a été mis à jour.", 
                        severity: 'success'
                    }))

                    setStatus('success')
                }
            })
            .catch(_ => setStatus('error'))

    }

    
    if(status === 'success') return <Navigate to="/" />


    return <Layout tab='profile' title="Changer d'abonnement">

        {status === 'loading' &&    
            <Loader />
        }

        {status === 'error' &&
        
            <Alert severity="error">
                Une erreur s'est produite. Veuillez réessayer.
            </Alert>
        }

        {['ready', 'error'].includes(status) && 

            <Subscriptions 
                onSelect={handleSelect}
                options={subscriptionKeys.filter(k => k !== company?.subscription)}
            />
        }

    </Layout>
}


