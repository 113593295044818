import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

export type Toast = {
  message: string
  severity?: 'success' | 'info' | 'warning' | 'error',
  duration?: number
}

export interface ToastState {
  value: Toast | null
}

const initialState: ToastState = {
  value: null
}

export const ToastSlice = createSlice({
    name: 'toast',
    initialState,
    reducers: {
      setToast: (state, action: PayloadAction<Toast | null>) => {
        state.value = action.payload
      },
    },
})


export const { setToast } = ToastSlice.actions

export default ToastSlice.reducer