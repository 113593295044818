import { Visibility, VisibilityOff } from "@mui/icons-material";
import { TextField, InputAdornment } from "@mui/material";
import { ChangeEvent, useState } from "react";

type PasswordProps = {
    label?: string;
    value: string;
    onChange: (event: ChangeEvent<HTMLInputElement>) => void;
};

export default function Password({ label, value, onChange }: PasswordProps) {

    const [show, setShow] = useState<Boolean>(false)

    return (
        <TextField
            label={label || 'Mot de passe'}
            size='small'
            type={show ? 'text': 'password'}
            value={value}
            onChange={onChange}
            required
            inputProps={{
                minLength: 8,
                maxLength: 20
            }}
            InputProps={{
                endAdornment: 
                    <InputAdornment position='end' onClick={() => setShow(!show)}>
                        {show ? <Visibility/> : <VisibilityOff/>}
                    </InputAdornment>
            }}
            autoComplete="new-password"
        />
    )

}
