import { Stack, TextField as Field } from "@mui/material";
import { useEffect, useState } from "react";
import { Company } from "../../models";
import Loader from "../../components/ui/Loader";
import API from "../../services/API";
import { LoadingButton } from "@mui/lab";
import { useDispatch } from "react-redux";
import { setToast } from "../../redux/toastSlice";
import Address from "../../components/form/Address";
import { Check } from "@mui/icons-material";
import CompanyLogo from "./CompanyLogo";

export default function CompanyForm(){

    const dispatch = useDispatch()

    const [data, setData] = useState<Company | null>(null)

    const [status, setStatus] = useState<'loading' | 'ready' | 'submit'>('loading')

    useEffect(() => {

        API.get('/company')
            .then(r => {
                setData(r.data)
                setStatus('ready')
            })

    }, [])

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()

        API.put('/company', data)
            .then(_ => {
                dispatch(setToast({
                    message: "Les informations ont été enregistrées avec succès.", 
                    severity: 'success'
                }))
            })
            .catch(e => {
                dispatch(setToast({
                    message: e?.data?.error || "Une erreur s'est produite.", 
                    severity: 'error'
                }))
            })
            .finally(() => setStatus('ready'))

    }

    return <form onSubmit={handleSubmit}>

        {data && <Stack spacing={3}>

            <Field
                label='Nom'
                size='small'
                value={data.name}
                onChange={e => setData({...data, name: e.target.value})}
                required
            />


            <Field
                label='Email'
                size='small'
                type='email'
                value={data.email}
                onChange={e => setData({...data, email: e.target.value})}
                required
            />


            <Address 
                value={data}
                onChange={(_, v) => setData({...data,
                    address: v.address,
                    zip: v.zip,
                    city: v.city,
                    country: v.country
                })}
            />

            <CompanyLogo
                company={data}
                onChange={setData}
            />

            <LoadingButton
                type='submit'
                variant='outlined'
                startIcon={<Check />}
                loading={status === 'submit'}
            >
                Enregistrer
            </LoadingButton>

        </Stack>}

        {status === 'loading' && <Loader />}

    </form>
}