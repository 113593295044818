export interface Quiz {
    id?: number, 
    createdAt?: string,
    updatedAt?: string,
    uuid?: string,
    title: string,
    backgroundColor: string | null,
    mainColor: string | null,
    logo?: string | null,
}

export let emptyQuiz = {
    title: "Votre avis compte !",
    backgroundColor: '#0b132b',
    mainColor: '#23ce6b'
}