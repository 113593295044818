import { Autorenew, ContentCopy } from "@mui/icons-material"
import { LoadingButton } from "@mui/lab"
import { Button } from "@mui/material"
import { useState } from "react"
import { setToast } from "../../redux/toastSlice"
import { useDispatch } from "react-redux"
import API from "../../services/API"

export default function ApiKey(){

    const dispatch = useDispatch()

    const [loading, setLoading] = useState<boolean>(false)

    const [value, setValue] = useState<string | null>(null)

    const handleGenerate = () => {

        setLoading(true)

        API.get('/company/apikey')
            .then(r => setValue(r.data.value))
            .finally(() => setLoading(false))
    }

    const handleCopy = () => {
        try{

            navigator.clipboard.writeText(value || '')

            dispatch(setToast({
                severity: 'success',
                message: "La clé API a été copiée dans le presse-papier.",
                duration: 6000
            }))

        }catch(e){
            console.error(e)
        }
    }

    return value ? 
        <Button 
            endIcon={<ContentCopy />}
            onClick={handleCopy}
        >
            {value}
        </Button>
        : 
        <LoadingButton
            onClick={handleGenerate}
            loading={loading}
            startIcon={<Autorenew/>}
        >
            Générer une clé d'API
        </LoadingButton>
}