import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, Stack, TextField } from "@mui/material"
import { Review } from "../../models";
import { LoadingButton } from "@mui/lab";
import { useState } from "react";
import API from "../../services/API";
import { setToast } from "../../redux/toastSlice";
import { Send } from "@mui/icons-material";
import { useDispatch } from "react-redux";

type ReviveModalProps = {
    review: Review | null,
    onClose: () => void,
    onSuccess: () => void
}

type Mode = 'email' | 'sms';

export default function ReviveModal({review, onClose, onSuccess}: ReviveModalProps){

    const dispatch = useDispatch()

    const [status, setStatus] = useState<'ready' | 'error' | 'submit'>('ready')

    const [mode, setMode] = useState<Mode>('email')


    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()

        setStatus('submit')

        API.post(`/reviews/${review?.id}/revive/${mode}`, null)
            .then(r => {

                setStatus('ready')

                onSuccess()

                dispatch(setToast({
                    severity: 'success',
                    message: `La demande d'avis a été renvoyée`
                }))

            })
            .catch(e => {
                setStatus('error')
            })

    }


    return <Dialog open={review ? true : false} onClose={onClose}>

        <DialogTitle>Envoyer une relance</DialogTitle>

        <form onSubmit={handleSubmit}>

            <DialogContent>

                <Stack spacing={3} sx={{width: window.innerWidth > 600 ? 500 : 300}}>

                    {status === 'error' && 
                        <Alert severity='error'>Une erreur s'est produite.</Alert>
                    }

                    {['ready','submit','error'].includes(status) && <>

                        <TextField
                            select
                            label="Mode d'envoi"
                            value={mode}
                            onChange={e => setMode(e.target.value as Mode)}
                            required
                            size='small'
                        >
                            <MenuItem value="email">Email</MenuItem>
                            <MenuItem value="sms">SMS</MenuItem>
                        </TextField>

                    </>}
                    

                </Stack>

            </DialogContent>

            <DialogActions>

                <Button onClick={onClose}>Annuler</Button>

                <LoadingButton
                    type='submit'
                    loading={status === 'submit'}
                    variant='contained'
                    startIcon={<Send />}
                >
                    Envoyer
                </LoadingButton>

            </DialogActions>

        </form>

    </Dialog>
}