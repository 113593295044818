import { Alert, Button, IconButton, Stack, Link as A, Avatar, useTheme } from "@mui/material";
import Layout from "../../layouts/Layout";
import { useState, useEffect } from "react";
import { Contact, emptyContact } from "../../models";
import API from "../../services/API";
import moment from "moment";
import { Add, Delete, Edit, Send, UploadOutlined } from "@mui/icons-material";
import ContactForm from "./Form";
import { Link } from "react-router-dom";
import { GridColDef } from '@mui/x-data-grid';
import Grid from "../../components/ui/Grid";
import { useDispatch } from "react-redux";
import { setToast } from "../../redux/toastSlice";
import initials from "../../services/initials";
import SendQuiz from "./SendQuiz";


export default function Index(){

    const theme = useTheme()

    const dispatch = useDispatch()

    const [contacts, setContacts] = useState<Contact[]>([])

    const [status, setStatus] = useState<'loading' | 'ready' | 'error'>('loading')

    const [selectedIds, setSelectedIds] = useState<number[]>([])

    const [form, setForm] = useState<Contact | null>(null)

    const [send, setSend] = useState<boolean>(false)

    useEffect(() => {

        API.get('/company/contacts')
            .then(r => {
                setContacts(r.data)
                setStatus('ready')
            })
            .catch(e => {
                console.error(e?.data)
                setStatus('error')
            })

    }, [])

    
    const columns: GridColDef[] = [
        {
            field: 'initials',
            headerName: '',
            width: 60,
            valueGetter: p => initials(p.row.firstname, p.row.lastname),
            renderCell: p => 
                <Avatar  sx={{bgcolor: theme.palette.primary.light, width: 40, height: 40}}>
                    <small>{p.value}</small>
                </Avatar>
        },
        {
            field: 'lastname',
            headerName: 'Nom',
            width: 160,
            valueGetter: p => p.row.lastname,
        },
        
        {
            field: 'firstname',
            headerName: 'Prénom',
            width: 160,
            valueGetter: p => p.row.firstname,
        },
        {
            field: 'email',
            headerName: 'Email',
            width: 280,
            valueGetter: p => p.row.email,
            renderCell: p => <A href={'mailto:' + p.value} color='inherit'>{p.value}</A>
        },
        {
            field: 'phone',
            headerName: 'Téléphone',
            width: 150,
            valueGetter: p => p.row.phone,
            renderCell: p => <A href={'tel:' + p.value} color='inherit'>{p.value}</A>
        },
        {
            field: 'list',
            headerName: 'Liste',
            width: 150,
            valueGetter: p => p.row.list,
        },
        {
            field: 'createdAt',
            headerName: 'Créé le',
            type: 'date',
            width: 160,
            valueGetter: p => new Date(p.row.createdAt),
            renderCell: p => moment(p.row.createdAt).format('DD/MM/YYYY à HH:mm')
        },
        {
            field: 'edit',
            headerName: '',
            width: 40,
            valueGetter: p => p.row.id,
            renderCell: p => 
                <IconButton size='small' onClick={() => setForm(p.row)}>
                    <Edit />
                </IconButton>
        },
        {
            field: 'delete',
            headerName: '',
            width: 40,
            valueGetter: p => p.row.id,
            renderCell: p => 
                <IconButton size='small' onClick={() => handleDelete(p.row.id)}>
                    <Delete />
                </IconButton>
        }
    ]

    const handleDelete = (id: number): boolean => {
        
        if(!window.confirm("Êtes-vous sûr de vouloir supprimer ce contact ?")) return false

        const old = [...contacts];
        let copy = [...contacts].filter(i => i.id !== id)
        setContacts(copy)

        API.delete(`/contacts/${id}`)
            .then(r => {
                console.log(r.data)
                dispatch(setToast({message: "Le contact a été supprimé.", severity: "info"}))
            })
            .catch(_ => {
                setContacts(old)
                dispatch(setToast({message: "Le contact n'a pas pu être supprimé.", severity: "error"}))
            })

        return true
    }

    const handleSubmit = (contact: Contact): void => {
        let copy = [...contacts]
        copy = copy.filter(c => c.id !== contact.id)
        copy.push(contact)
        setContacts(copy)
        setForm(null)
    }

    return <Layout tab='contacts' title="Contacts">

        <Stack>

            <Grid 
                columns={columns}
                rows={contacts}
                loading={status === 'loading'}
                pageSize={10}
                onSelect={ids => setSelectedIds(ids)}
                toolbar={<>

                    <Button 
                        startIcon={<Add />} 
                        onClick={() => setForm(emptyContact)}
                    >
                        Ajouter
                    </Button>

                    <Button 
                        startIcon={<UploadOutlined />}
                        component={Link}
                        to='/contacts/import'
                    >
                        Importer
                    </Button>

                    {selectedIds.length > 0 && 
                        <Button 
                            startIcon={<Send />}
                            onClick={() => setSend(true)}
                        >
                            Demander {selectedIds.length} avis
                        </Button>
                    }

                </>}
            />

            {status === 'error' && 
                <Alert severity='error'>Une erreur s'est produite.</Alert>
            }

        </Stack>

        <ContactForm 
            contact={form}
            onClose={() => setForm(null)}
            onSuccess={handleSubmit}
        />

        <SendQuiz
            open={send}
            onClose={() => setSend(false)}
            contacts={contacts.filter(c => selectedIds.includes(c?.id || 0))}
        />

    </Layout>
}


