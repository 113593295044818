import { Stack, Typography as Typo} from "@mui/material";
import Subscriptions from "./Subscriptions";
import { useNavigate } from "react-router-dom";

export default function Subscribe(){

    const navigate = useNavigate()

    return (
        <div className="layout-center">

            <Stack spacing={3} sx={{my: 3}}>

                <Typo variant='h1' align="center">Inscription à ReviewLib</Typo>

                <Typo variant='h4' align="center">Choisissez votre abonnement :</Typo>

                <Subscriptions onSelect={value => navigate(`/register/${value}`)} />

            </Stack>


        </div>
    )
}