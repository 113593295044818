import { Review } from "../models";

export default function ratingAverage(reviews: Review[]): number {

    let sum = reviews.map(r => r.rating / 20).reduce((a,b) => a + b, 0)

    let count = reviews.length

    let avg = count > 0 ? (sum / count) : 0

    return parseFloat(avg.toFixed(1))
}