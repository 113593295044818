import { AutoAwesome } from "@mui/icons-material";
import { Alert, LinearProgress, Stack } from "@mui/material";
import { useEffect } from "react";
import API from "../../services/API";

type RedirectProps = {
    uuid: string | undefined;
    url: string;
}

export default function Redirect({uuid, url}: RedirectProps){

    useEffect(() => {

        API.put(`/reviews/${uuid}/complete`, null)

        const timeout = setTimeout(() => {

            window.location.href = url
        
        }, 4000)

        return () => clearTimeout(timeout)

    }, [uuid, url])

    return (

        <Stack>

            <LinearProgress color="success" />

            <Alert severity="success" icon={<AutoAwesome />}>

                Merci pour votre avis ! Vous allez être redirigé sur notre site pour partager votre expérience.

            </Alert>

        </Stack>
    )
}