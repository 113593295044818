import { Company } from "../../models"
import { Box, Typography } from "@mui/material"
import useCompany from "../../services/useCompany"

export default function Logo(){

    const company: Company | null = useCompany()

    return company?.logo ? 
            
        <Box>
            <img
                src={company.logo}
                width={150}
                alt="Logo"
            />
        </Box>

        :

        <Typography variant="h1">
            <b>{company?.name}</b>
        </Typography>
    
}
