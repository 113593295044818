import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Stack, Typography } from "@mui/material"
import { LoadingButton } from "@mui/lab";
import { useState } from "react";
import API from "../../services/API";
import { useDispatch } from "react-redux";
import { setToast } from "../../redux/toastSlice";
import { useNavigate } from "react-router-dom";

type DeleteModalProps = {
    open: boolean,
    onClose: () => void
}

export default function DeleteModal({open, onClose}: DeleteModalProps){

    const [status, setStatus] = useState<'ready' | 'deleting' | 'error'>('ready')

    const [confirm, setConfirm] = useState('')

    const navigate = useNavigate()

    const dispatch = useDispatch()
    
    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()

        if(confirm !== 'SUPPRIMER') return onClose()

        setStatus('deleting')

        API.delete(`/company`)
            .then(_ => {
                navigate('/login')

                dispatch(setToast({
                    severity: 'success',
                    message: "Votre compte ReviewLib a été définitivement supprimé."
                }))
            })
            .catch(_ => setStatus('error'))
    }

    
    return <Dialog open={open} onClose={onClose}>

        <DialogTitle>Supprimer le compte</DialogTitle>

        <form onSubmit={handleSubmit}>

            <DialogContent>

                <Stack spacing={3} sx={{width: window.innerWidth > 600 ? 500 : 300}}>

                    <Alert severity="warning">
                        Vous vous apprêtez à supprimer votre abonnement ReviewLib et l'ensemble de vos données. Cette opération est <b>irréversible</b>.
                    </Alert>

                    <Typography>Pour confirmer la suppression du compte, entrez le mot SUPPRIMER en majuscule dans le champ ci-dessous :</Typography>

                    {status === 'error' &&
                        <Alert severity="error">
                            Une erreur s'est produite.
                        </Alert>
                    }

                    <TextField 
                        label="Supprimer le compte"
                        name='confirm'
                        type='text'
                        value={confirm}
                        onChange={e => setConfirm(e.target.value)}
                    />

                </Stack>

            </DialogContent>

            <DialogActions>

                <Stack spacing={3} direction='row'>

                    <Button color="secondary" onClick={onClose}>Annuler</Button>

                    <LoadingButton
                        type='submit'
                        loading={status === 'deleting'}
                        variant='contained'
                        color='error'
                    >
                        Supprimer
                    </LoadingButton>

                </Stack>

            </DialogActions>

        </form>

    </Dialog>
}