import { useQuery } from "react-query";
import { Company } from "../models";
import API from "./API";

export default function useCompany(): Company | null {

    const query = useQuery({
        queryKey: 'company',
        queryFn: () => API.get('/company').then(r => r.data)
    })

    return query.isSuccess ? query.data : null
}