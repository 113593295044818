import { Alert, Snackbar } from "@mui/material"
import type { RootState } from '../../redux/store';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { setToast } from '../../redux/toastSlice';

export default function Toast(){

    const toast = useSelector((state: RootState) => state.toast.value)

    const dispatch = useDispatch()

    return toast && 
        <Snackbar 
            open={toast !== null} 
            autoHideDuration={toast.duration || 4000} 
            onClose={() => dispatch(setToast(null))}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
            <Alert 
                onClose={() => dispatch(setToast(null))} 
                severity={toast.severity || 'success'}
                variant='filled'
            >
                {toast.message}
            </Alert>
        </Snackbar>
}