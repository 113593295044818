import { Avatar, Stack, Tab, useTheme, Typography as Typo, Box, Button, Alert } from "@mui/material";
import Layout from "../../layouts/Layout";
import { useState } from "react";
import initials from "../../services/initials";
import type { RootState } from '../../redux/store'
import { useSelector } from 'react-redux'
import moment from "moment";
import { InsertDriveFile, Loyalty, Person, PersonRemove, Settings as SettingsIcon, Work } from "@mui/icons-material";
import UserForm from "./UserForm";
import CompanyForm from "./CompanyForm";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import DeleteModal from "./DeleteModal";
import Invoices from "./Invoices";
import { Link } from "react-router-dom";
import Settings from "./Settings";

export default function Profile(){

    const theme = useTheme()

    const user = useSelector((state: RootState) => state.user.value)

    const [tab, setTab] = useState<'user' | 'company' | 'invoices' | 'settings'>('user')

    const [deleteModal, setDeleteModal] = useState<boolean>(false)

    return <Layout tab='profile'>

        <Stack spacing={2} sx={{maxWidth: 800, margin: 'auto'}}>

            {user &&
                <Stack direction='row' spacing={2} alignItems='center' >
                    
                    <Avatar sx={{bgcolor: theme.palette.primary.light, width: 60, height: 60}}>
                        {initials(user.firstname, user.lastname)}
                    </Avatar>

                    <Stack>
                        <Typo variant='h4'>{user.firstname + ' ' + user.lastname}</Typo>
                        <Typo variant='body2' color='text.secondary'>
                            Créé le {moment(user.createdAt).format('DD/MM/YYYY')}
                        </Typo>
                    </Stack>

                </Stack>
            }

            {user?.isOwner &&
                <Alert severity="info" icon={<Loyalty />}>
                    Abonnement <b>{user.subscription?.toUpperCase()}</b> <Link to="/upgrade"><u><small>Modifier</small></u></Link>
                </Alert>
            }

            <TabContext value={tab}>

                <Stack sx={{maxWidth: 800, margin: 'auto'}}>

                    <Box sx={{borderBottom: 1, borderColor: 'divider', mb: 1}}>

                        <TabList onChange={(_, value) => setTab(value)}>

                            <Tab label="Utilisateur" value='user' icon={<Person />} iconPosition="start"/>

                            <Tab label="Entreprise" value='company' icon={<Work />} iconPosition="start"/>

                            <Tab label="Factures" value='invoices' icon={<InsertDriveFile />} iconPosition="start"/>

                            <Tab label="Paramètres" value='settings' icon={<SettingsIcon />} iconPosition="start"/>

                        </TabList>

                    </Box>

                    <TabPanel value="user">

                        <UserForm />

                    </TabPanel>

                    <TabPanel value="company">

                        <CompanyForm />

                    </TabPanel>

                    <TabPanel value="invoices">

                        <Invoices />

                    </TabPanel>

                    <TabPanel value="settings">

                        <Settings />

                    </TabPanel>

                </Stack>

            </TabContext>

            {(user?.isOwner && tab === 'user') &&
                <Button 
                    color="error" 
                    startIcon={<PersonRemove />} 
                    onClick={() => setDeleteModal(true)}
                >
                    Supprimer le compte
                </Button>
            }

            <DeleteModal 
                open={deleteModal}
                onClose={() => setDeleteModal(false)}
            />

        </Stack>

    </Layout>
}