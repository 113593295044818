
export default function readFile(file: File): Promise<string> {

    const reader = new FileReader();

    return new Promise((resolve, reject) => {

        reader.onload = event => resolve((event.target as FileReader).result as string);

        reader.onerror = error => reject(error);

        reader.readAsText(file);
    });
}