import { LinearProgress, Stack, Typography } from "@mui/material"
import { Review } from "../../models"
import { Star } from "@mui/icons-material"

type HistogramProps = {
    reviews: Review[]
}

export default function Histogram({reviews}: HistogramProps){

    const bar = (rate: number) => {

        let count: number = reviews.filter(r => Math.round(r.rating / 20) === rate).length
        let percent: number = reviews.length === 0 ? 0 : Math.round(count / reviews.length * 100)

        return {rate, count, percent}
    }

    const barWidth = window.innerWidth > 800 ? 250 : 180

    return <Stack spacing={0.5}>

        {[5,4,3,2,1].map(i  => 

            <Stack direction='row' alignItems='center' spacing={1} key={i}>

                <Typography>{i}</Typography>

                <Star fontSize='small' color='action'/>

                <LinearProgress
                    variant='determinate' 
                    value={bar(i).percent} 
                    sx={{width: barWidth, height: 6}}
                />

                <Typography variant='body2' color='text.secondary'>
                    {bar(i).percent}% {bar(i).count ? `(${bar(i).count})` : ''}
                </Typography>

            </Stack>
        )}

    </Stack>
}