import './assets/css/index.css'
import { store } from './redux/store'
import { Provider } from 'react-redux'
import Toast from './components/ui/Toast'
import { ThemeProvider } from '@mui/material/styles'
import { BrowserRouter, Routes, Route } from 'react-router-dom';

/* PAGES */
import Register from './pages/account/Register';
import Login from './pages/account/Login';
import Logout from './pages/account/Logout';
import Reviews from './pages/reviews/Index';
import Contacts from './pages/contacts/Index';
import ContactsImport from './pages/contacts/Import';
import Profile from './pages/account/Profile';
import Quizzes from './pages/quizzes/Index';
import Form from './pages/form/Index';
import QuizDesign from './pages/quizzes/Design';
import generateMUITheme from './services/generateMUITheme'
import Subscribe from './pages/account/Subscribe'
import Pay from './pages/account/Pay'
import Upgrade from './pages/account/Upgrade'
import Landing from './pages/public/Landing'
import Embed from './pages/public/Embed'
import ForgotPassword from './pages/account/ForgotPassword'
import ResetPassword from './pages/account/ResetPassword'
import { QueryClient, QueryClientProvider } from 'react-query'

const queryClient = new QueryClient()

export default function App(){

    const theme = generateMUITheme('#23ce6b', '#0b132b')

    return <ThemeProvider theme={theme}>

        <QueryClientProvider client={queryClient}>

            <Provider store={store}>

                <BrowserRouter>
                    <Routes>
                        <Route path="/form/:uuid" element={<Form />}/>
                        <Route path="/subscribe" element={<Subscribe />}/>
                        <Route path="/register/:subscription" element={<Register />}/>
                        <Route path="/pay/:subscription" element={<Pay />}/>
                        <Route path="/login" element={<Login />}/>
                        <Route path="/logout" element={<Logout />}/>
                        <Route path="/contacts" element={<Contacts />}/>
                        <Route path="/contacts/import" element={<ContactsImport />}/>
                        <Route path="/quizzes" element={<Quizzes />}/>
                        <Route path="/quizzes/:id" element={<QuizDesign />}/>
                        <Route path="/profile" element={<Profile />}/>
                        <Route path="/upgrade" element={<Upgrade />}/>
                        <Route path="/experience/:uuid" element={<Landing />}/>
                        <Route path="/embed/:uuid" element={<Embed />}/>
                        <Route path="/password/forgot" element={<ForgotPassword />}/>
                        <Route path="/password/reset/:key" element={<ResetPassword />}/>
                        <Route path="/" element={<Reviews />}/>
                    </Routes>
                </BrowserRouter>

                <Toast />

            </Provider>

        </QueryClientProvider>
    
    </ThemeProvider>
}