import { Alert, IconButton, Rating, Stack } from "@mui/material";
import Layout from "../../layouts/Layout";
import { useState, useEffect, useMemo } from "react";
import { Review } from "../../models";
import API from "../../services/API";
import moment from "moment";
import { GridColDef } from '@mui/x-data-grid';
import Grid from "../../components/ui/Grid";
import { CopyAll, Delete, Send, Visibility, ZoomIn } from "@mui/icons-material";
import Zoom from "./Zoom";
import StatusChip from "./StatusChip";
import { useDispatch } from "react-redux";
import { setToast } from "../../redux/toastSlice";
import Stats from "./Stats";
import reviewStatus from "../../data/reviewStatus";
import { LoadingButton } from "@mui/lab";
import localeUrl from "../../services/localeUrl";
import ReviveModal from "./ReviveModal";
import Experience from "./Experience";

export default function Index(){

    const dispatch = useDispatch()

    const [reviews, setReviews] = useState<Review[]>([])

    const [status, setStatus] = useState<'loading' | 'ready' | 'error'>('loading')

    const [zoom, setZoom] = useState<Review | null>(null)

    const [revive, setRevive] = useState<Review | null>(null)

    const columns: GridColDef[] = [
        {
            field: 'zoom',
            headerName: '',
            width: 50,
            valueGetter: p => p.row.id,
            renderCell: p => 
                <IconButton size='small' onClick={() => setZoom(p.row)}>
                    <ZoomIn />
                </IconButton>
        },
        {
            field: 'name',
            headerName: 'Nom',
            width: 200
        },
        {
            field: 'status',
            headerName: 'Statut',
            width: 130,
            valueGetter: p => reviewStatus[p.row.status]?.title,
            renderCell: p => <StatusChip value={p.row.status}/>
        },
        {
            field: 'rating',
            headerName: 'Note',
            type: 'number',
            headerAlign: 'left',
            width: 150,
            valueGetter: p => p.row.rating / 20,
            renderCell: p => <Rating value={p.value} precision={0.5} readOnly />
        },
        {
            field: 'comment',
            headerName: 'Commentaire',
            width: 400,
        },
        {
            field: 'createdAt',
            headerName: 'Créé le',
            type: 'date',
            width: 160,
            valueGetter: p => new Date(p.row.createdAt),
            renderCell: p => moment(p.row.createdAt).format('DD/MM/YYYY à HH:mm')
        },
        {
            field: 'revivedAt',
            headerName: 'Relancé le',
            type: 'date',
            width: 160,
            valueGetter: p => p.row.revivedAt ? new Date(p.row.revivedAt) : null,
            renderCell: p => p.value ? moment(p.value).format('DD/MM/YYYY à HH:mm') : ''
        },
        {
            field: 'revive',
            headerName: 'Relancer',
            width: 90,
            valueGetter: p => p.row.id,
            renderCell: p => 
                <LoadingButton 
                    size='small' 
                    loading={revive === p.value}
                    onClick={() => setRevive(p.row)}
                    disabled={!p.row.contactId || p.row.status === 'completed'}
                >
                    <Send />
                </LoadingButton>
        },
        {
            field: 'show',
            headerName: 'Afficher',
            width: 80,
            valueGetter: p => p.row.uuid,
            renderCell: p => 
                <IconButton 
                    size="small"
                    target="_blank"
                    rel="noopener noreferrer"
                    href={localeUrl(`/form/${p.value}`)}
                >
                    <Visibility />
                </IconButton>
        },
        {
            field: 'share',
            headerName: 'Copier le lien',
            width: 120,
            valueGetter: p => p.row.uuid,
            renderCell: p => 
                <IconButton size='small' onClick={() => handleShare(p.value)}>
                    <CopyAll />
                </IconButton>
        },
        {
            field: 'actions',
            headerName: '',
            width: 50,
            valueGetter: p => p.row.id,
            renderCell: p => 
                <IconButton size='small' onClick={() => handleDelete(p.row.id)}>
                    <Delete />
                </IconButton>
        }
    ]

    useEffect(() => {

        API.get('/company/reviews')
            .then(r => {
                setReviews(r.data)
                setStatus('ready')
            })
            .catch(e => {
                console.error(e?.data)
                setStatus('error')
            })

    }, [])


    const handleDelete = (id: number): boolean => {
        
        if(!window.confirm("Êtes-vous sûr de vouloir supprimer cet avis ?")) return false

        setZoom(null)

        const old = [...reviews];
        let copy = [...reviews].filter(i => i.id !== id)
        setReviews(copy)

        API.delete(`/reviews/${id}`)
            .then(_ => dispatch(setToast({message: "L'avis a été supprimé.", severity: "info"})))
            .catch(_ => setReviews(old))

        return true
    }

    const handleShare = (uuid: string) => {
        try{

            const url = localeUrl(`/form/${uuid}`);

            navigator.clipboard.writeText(url)

            dispatch(setToast({
                severity: 'success',
                message: "Le lien du formulaire a été copié dans le presse-papier.",
                duration: 6000
            }))

        }catch(e){
            console.error(e)
        }
    }

    const handleRevive = () => {

        if(!revive) return false

        let newReview = {...revive, revivedAt: moment().toISOString()}

        let newReviews = [...reviews].filter(r => r.id !== revive?.id)

        newReviews.push(newReview)

        setReviews(newReviews)

        setRevive(null)
    }

    const sortedReviews = useMemo(() => reviews
        .sort((a: Review, b: Review) => 
            moment(b.createdAt).diff(moment(a.createdAt)
        )
    ), [reviews])

    return <Layout tab='home'>

        <Stack spacing={2}>

            <Stats reviews={reviews} />

            <Experience />

            <Grid
                columns={columns}
                rows={sortedReviews}
                loading={status === 'loading'}
                pageSize={10}
            />

            {status === 'error' && 
                <Alert severity='error'>Une erreur s'est produite.</Alert>
            }

            <Zoom 
                review={zoom}
                onClose={() => setZoom(null)}
            />

            <ReviveModal 
                review={revive || null}
                onClose={() => setRevive(null)}
                onSuccess={handleRevive}
            />

        </Stack>

    </Layout>
}

