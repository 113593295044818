export type ReviewStatus = {
    title: string;
    color: 'default' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning'
}

const reviewStatus: { [key: string]: ReviewStatus } = {
    created: {
        title: "Créé",
        color: 'default'
    },
    sent: {
        title: "Envoyé",
        color: 'default'
    },
    opened: {
        title: "Ouvert",
        color: 'default'
    },
    started: {
        title: "Démarré",
        color: 'secondary'
    },
    completed: {
        title: "Complété",
        color: 'success'
    },

}

export default reviewStatus;