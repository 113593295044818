import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { User } from '../models';

export interface UserState {
  value: User | null
}

const initialState: UserState = {
  value: null
}

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
      setUser: (state, action: PayloadAction<User | null>) => {
        state.value = action.payload
      },
    },
})


export const { setUser } = userSlice.actions

export default userSlice.reducer