import { CheckCircleOutlined } from "@mui/icons-material";
import { Divider, Paper, Stack, Typography } from "@mui/material";
import { ReactNode } from "react";


type SubscriptionProps = {
    title: string;
    description: ReactNode;
    optionsTitle?: ReactNode;
    options: string[];
    button: ReactNode;
    elevation: number;
};


export default function Subscription({title, description, optionsTitle, options, button, elevation = 3}: SubscriptionProps){

    return <Paper sx={{m: 2, width: 340}} elevation={elevation}>

        <Stack spacing={1}>

            <Stack spacing={3} sx={{p: 2}}>

                <Typography variant="h3" align="center">{title}</Typography>

                <Typography align="center">{description}</Typography>

                {button}

            </Stack>

            <Divider />

            <Stack spacing={2} sx={{p: 2}}>

                {optionsTitle &&
                    <Typography  align="center">{optionsTitle}</Typography>
                }

                {options.map((o,i) => 

                    <Stack direction='row' spacing={2} key={i}>

                        <CheckCircleOutlined sx={{fontSize: 24}} />

                        <Typography sx={{fontSize: '0.98em'}}>
                            {o}
                        </Typography>

                    </Stack>
                
                )}


            </Stack>

        </Stack>

    </Paper>

}