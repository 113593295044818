import { Typography } from "@mui/material";
import { Question } from "../../models"
import { Answer } from "../../models/Answer";
import Field from "./Field";
import { LoadingButton } from "@mui/lab";


type ContentProps = {
    question: Question;
    answer: Answer | null;
    onAnswerChange: (answer: Answer) => void
    loading: boolean;
}

export default function Content({question, answer, onAnswerChange, loading}: ContentProps){

    return <>
    
        <Typography>{question?.title}</Typography>

        <Field
            question={question}
            answer={answer}
            onChange={onAnswerChange}
        />

        <LoadingButton
            variant='contained'
            type='submit'
            loading={loading}
            disabled={answer ? false : !question.optional}
        >
            Valider
        </LoadingButton>
    
    </>
}