import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField as Field, Stack } from "@mui/material"
import { Contact } from "../../models";
import { LoadingButton } from "@mui/lab";
import { useEffect, useState } from "react";
import API from "../../services/API";
import Phone from "../../components/form/Phone";
import { useDispatch } from "react-redux";
import { setToast } from "../../redux/toastSlice";

type ContactFormProps = {
    contact: Contact | null
    onClose: () => void
    onSuccess: (contact: Contact) => void
}

type Status = 'ready' | 'submit' | 'error'

export default function Form({ contact, onClose, onSuccess }: ContactFormProps){

    const dispatch = useDispatch()
    
    const [status, setStatus] = useState<Status>('ready')

    const [message, setMessage] = useState<string>('')

    const [data, setData] = useState<Contact | null>(null)
    

    useEffect(() => {

        setData(contact)

        setStatus('ready')

    }, [contact])


    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()

        setStatus('submit')

        save()
            .then(r => {

                dispatch(setToast({
                    message: "Le contact a été enregistré avec succès",
                    severity: 'success'
                }))

                onSuccess(r.data)
            })
            .catch(e => {
                setStatus('error')
                setMessage(e?.data?.error || "Une erreur s'est produite.")
            })
    }


    const save = async () => contact?.id ? 
        API.put(`/contacts/${contact.id}`, data) : 
        API.post(`/contacts`, data)


    return data && <Dialog open={true} onClose={onClose}>

        <DialogTitle>{contact?.id ? "Modifier le" : "Créer un"} contact</DialogTitle>

        <form onSubmit={handleSubmit}>

            <DialogContent>

                <Stack spacing={2} sx={{width: window.innerWidth > 600 ? 500 : 300}}>

                    {status === 'error' && 
                        <Alert severity='error'>{message}</Alert>
                    }

                    <Field
                        label='Nom'
                        size='small'
                        value={data.lastname}
                        onChange={e => setData({...data, lastname: e.target.value})}
                        required
                    />

                    <Field
                        label='Prénom'
                        size='small'
                        value={data.firstname}
                        onChange={e => setData({...data, firstname: e.target.value})}
                        required
                    />

                    <Field
                        label='Email'
                        size='small'
                        type='email'
                        value={data.email}
                        onChange={e => setData({...data, email: e.target.value})}
                        required
                        autoComplete="username"
                    />

                    <Phone
                        value={data.phone || ''}
                        onChange={e => setData({...data, phone: e.target.value || null})}
                    />

                    <Field
                        label='Nom de la liste de contacts'
                        size='small'
                        value={data.list || ''}
                        onChange={e => setData({...data, list: e.target.value || null})}
                    />

                </Stack>

            </DialogContent>

            <DialogActions>

                <Button onClick={onClose}>Annuler</Button>

                <LoadingButton
                    type='submit'
                    loading={status === 'submit'}
                    variant='contained'
                >
                    Valider
                </LoadingButton>

            </DialogActions>

        </form>

    </Dialog>
}