import { Share } from "@mui/icons-material";
import { Box, Button } from "@mui/material";
import { useState } from "react";
import ShareModal from "../../components/ui/ShareModal";
import localeUrl from "../../services/localeUrl";
import useCompany from "../../services/useCompany";


export default function Experience(){

    const [modal, setModal] = useState<boolean>(false)

    const company = useCompany()

    return <>

        <Box>
            <Button 
                startIcon={<Share />}
                variant="outlined"
                onClick={() => setModal(true)}
            >
                Partager mes avis
            </Button>
        </Box>

        <ShareModal 
            link={localeUrl(`/experience/${company?.uuid}`)}
            title="Partager mes avis"
            open={modal}
            onClose={() => setModal(false)}
        />
        
    </>
}