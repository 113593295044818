import { Alert, Stack, TextField as Field, Button } from "@mui/material";
import File from "../../components/form/File";
import Layout from "../../layouts/Layout";
import readFile from "../../services/readFile";
import parseCSV, { CSVRow } from "../../services/parseCsv";
import { useState } from "react";
import { GridColDef } from '@mui/x-data-grid';
import { LoadingButton } from "@mui/lab";
import { Close, Save } from "@mui/icons-material";
import API from "../../services/API";
import { Contact } from "../../models";
import { useNavigate } from "react-router-dom";
import { emailPattern, phonePattern } from "../../services/patterns";
import { useDispatch } from "react-redux";
import { setToast } from "../../redux/toastSlice";
import Grid from "../../components/ui/Grid";


export default function Import(){

    const navigate = useNavigate()

    const dispatch = useDispatch()

    const [list, setList] = useState<string>('')

    const [error, setError] = useState<string | null>(null)

    const [saving, setSaving] = useState<boolean>(false)

    const [contacts, setContacts] = useState<Contact[]>([])

    const handleFile = (file: File | null) => {
        
        if(!file) return setError(`Le format du fichier n'est pas valide`)

        setContacts([])

        setError(null)

        readFile(file)
            .then(csvContent => {
                const extract = extractContactsFromCsv(csvContent, list)
                console.log(extract)
                setContacts(extract.contacts)
                setError(extract.error)
            })
            .catch(_ => {
                setError(`Le format du fichier n'est pas valide`)
                setContacts([])
            })

    }

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {

        event.preventDefault();

        if(contacts.length === 0) return false;

        setSaving(true)
        setError(null)

        saveContacts(contacts, list)
            .then(_ => {

                dispatch(setToast({
                    message: "Les contacts ont été importés avec succès",
                    severity: 'success'
                }))

                navigate('/contacts')
            })
            .catch(e => {
                console.log(e.response?.status, e.response?.data)
                setSaving(false)
                setError(`Une erreur s'est produite. Tous les contacts n'ont pas pu être importés.`)
            })
    }

    return <Layout tab='contacts' title='Importer des contacts' backLink='/contacts'>

        <form onSubmit={handleSubmit}>

            <Stack spacing={2} sx={{maxWidth: 700, margin: 'auto'}}>

                <Stack spacing={2}>

                    <Field
                        label="Nom de la liste"
                        value={list}
                        onChange={e => setList(e.target.value)}
                        inputProps={{minLength: 3, maxLength: 200}}
                        required
                        size='small'
                        autoFocus
                    />

                    {contacts.length === 0 &&
                        <File
                            id='import-contacts'
                            label="Importer un fichier CSV"
                            onChange={handleFile}
                            accept={['csv']}
                        />
                    }

                </Stack>

                {error && 
                    <Alert severity='error'>{error}</Alert>
                }

                <Grid
                    columns={columns}
                    rows={contacts}
                    toolbar={<>

                        {contacts.length > 0 && 
                            <Button
                                startIcon={<Close />}
                                onClick={() => {
                                    setContacts([])
                                    setError(null)
                                }}
                            >
                                Annuler
                            </Button>
                        }

                        {contacts.length > 0 && 
                            <LoadingButton
                                startIcon={<Save />}
                                loading={saving}
                                type='submit'
                            >
                                Enregistrer
                            </LoadingButton>
                        }
                    
                    </>}
                />

                <Alert severity='info'>
                    Le fichier d'import doit contenir 4 colonnes : Nom, Prénom, Email et Téléphone. La ligne d'entête n'est pas prise en compte. Le fichier doit être au format CSV avec la virgule comme séparateur de champ.
                </Alert>

            </Stack>

        </form>
        
    </Layout>
}


const columns: GridColDef[] = [
    {
        field: 'lastname',
        headerName: 'Nom',
        width: 150,
    },
    {
        field: 'firstname',
        headerName: 'Prénom',
        width: 130,
    },
    {
        field: 'email',
        headerName: 'Email',
        width: 260,
    },
    {
        field: 'phone',
        headerName: 'Téléphone',
        width: 150,
    },
]


const saveContacts = async (contacts: Contact[], list: string): Promise<Contact[]> => {

    let newContacts: Contact[] = []

    for(let i = 0; i < contacts.length; i++){
        let contact = {...contacts[i]}
        delete contact.id
        contact.list = list
        const response = await API.post('/contacts', contact)
        newContacts.push(response.data)
    }

    return newContacts;
}


type ExtractResult = {
    error: string | null,
    contacts: Contact[]
}

const extractContactsFromCsv = (csvContent: string, list: string): ExtractResult => {

    const rows: CSVRow[] = parseCSV(csvContent)

    let contacts: Contact[] = []

    let error: string | null = null

    for(let i = 1; i < rows.length; i++){

        let row = rows[i]

        if(i === 1 && Object.keys(row).length !== 4){
            error = `Le fichier doit contenir 4 colonnes. Il en contient ${Object.keys(row).length}.`
            break;
        }

        let lastname = row[0]?.trim()
        let firstname = row[1]?.trim()
        let email = row[2]?.trim()
        let phone = row[3]?.trim() || null

        if(!lastname || !firstname || !email){

            if(i === rows.length - 1){

                break;

            }else{
                error = `Données invalides à la ligne ${i + 1}`
                break;
            }
        }

        if(!emailPattern.test(email)){
            error = `Email incorrect à la ligne ${i + 1} : "${email}"`
            break;
        }

        if(phone && !phonePattern.test(phone)){
            error = `Téléphone incorrect à la ligne ${i + 1} : "${phone}"`
            break;
        }

        contacts.push({id: i, lastname, firstname, email, phone, list})
    }

    return {error, contacts}
}